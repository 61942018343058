.lang-selector {
  display: none;
  position: relative;
  font-size: 20px;
  font-weight: 400;
  text-transform: capitalize;

  @media(min-width: 768px) {
    display: block;
  }
  
  > a {
    color: black;
    text-decoration: none;

    .is-home:not(.menu-is-open) & {
      color: white;
    }
  }

  > ul {
    position: absolute;
    top: 0;
    right: calc(100% + 15px);
    visibility: hidden;
    opacity: 0;
    transition: opacity .3s;
    display: flex;

    a {
      transition: all .3s;
      text-decoration: none;
      color: rgba(black, 0.3);

      .is-home:not(.menu-is-open) & {
        color: rgba(white, 0.3);
      }

      &:hover {
        color: rgba(black, 0.6);

        .is-home:not(.menu-is-open) & {
          color: rgba(white, 0.6);
        }
      }
    }

    li + li {
      margin-left: 15px;
    }

    &.show {
      visibility: visible;
      opacity: 1;
    }
  }
}