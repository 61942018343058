// Customized hero codyhouse and more extera customizations

.hero {
    margin: auto;
    overflow: hidden;

    @media (min-width: 767px){
        max-width: calc(100% - 2*var(--component-padding));
        border-left: 1px solid var(--color-borders);
        border-right: 1px solid var(--color-borders);
    }
    
    @include breakpoint(md) {
        min-height: 430px;
    }

    @media (min-width: 1680px){
        max-width: 1600px;        
    }

    &--lg {
        @include breakpoint(md) {
            min-height: 70vh;
        }
    }

    .is-search & {
        min-height: inherit;
        height: 30vh;
        //background-color: var(--color-neutro);
        background-color: var(--grey-sm);
        padding-bottom: 60px;

        @media (min-width: 767px){
            height: 40vh;
        }

        &:after {
            //display: none;
        }
    }

    &.no-shade,
    .is-reserved-area &,
    .is-content &,
    .is-designer & {
        background-color: var(--color-neutro);
        //background-color: var(--grey-sm);
    }

    .container {
        position: relative;
        height: 100%;
    }

    &__filters {
        width: 100%;
        height: 80px;
        padding: 0 30px;
        // rimuovo bg
        //background-color: #f7f7f9;
        display: flex;
        align-items: center;
        justify-content: space-between;

        position: absolute;
        left: 0;
        bottom: -1px;
        z-index: 10;

        // rimuovo al momento
        display: none;

        @media (min-width: 767px) {
            width: 410px;
            height: 100px;
            padding: 0 70px;
        }

        .icon-left,
        .lbl {
            color: #28282d;
        }

        .icon-left {
            font-size: 18px;
            margin-right: 10px;
        }

        .lbl {
            font-size: 20px;
            font-weight: 400;
            //font-family: var(--font-primary);

            @media (min-width: 767px) {
                font-size: 24px;
            }
        }

        .fa-angle-down {
            font-size: 24px;
            color: #dedee0;
        }

        .full {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 10;
        }
    }

    &__full {
        height: 100vh;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;

        .tablet & {
            height: 80vh;
        }

        .mobile & {
            height: 70vh;
        }

        .discover {
            font-size: 16px;
            text-transform: uppercase;
            text-decoration: none;
            position: absolute;
            bottom: 50px;
            left: 50%;
            z-index: 10;
            transform: translateX(-50%);
            font-weight: bold;
            line-height: 2.19;
            color: white;
            cursor: pointer;

            @media (min-width: 992px){
                bottom: 100px;
            } 

            &:after {
                content: '';
                height: 50px;
                width: 1px;
                background-color: white;
                position: absolute;
                top: 100%;
                left: 50%;

                transform: translateY(0);
                transition: transform .25s ease-in-out 0s;

                @media (min-width: 992px){
                    height: 100px;
                }
            }

            &:hover:after {
                transform: translateY(30%);
            }
        }

        &:after {
            content: '';
            width: 100%;
            height: 100%;
            background-image: linear-gradient(to top, rgba(0,0,0,.6), rgba(0, 0, 0, 0));
            position: absolute;
            bottom: 0;
            left: 0;
            
            .is-piazza-prod &,
            .is-piazza-col &,
            .is-prodotto & {
                height: 307px;                
            }
        }

        &.no-shade:after {
            display: none;
        }

        &.no-shade {
            background-color: var(--color-neutro);

            /*.is-news &,
            .is-content & {
                background-color: var(--color-neutro);
            }*/

            .is-news & {
                height: 80vh;

                @media (min-width: 992px){
                    height: 90vh;
                }
            }
        }

        &.with-content {
            .container {
                display: flex;
                align-items: center;
                justify-content: center;

                .is-search & {
                    align-items: flex-end;
                }
            }

            &:after {
                height: 100%;
            }
        }

        .is-reserved-area &,
        .is-content &,
        .is-designer & {
            height: 40vh;
            min-height: inherit!important;
            padding-bottom: 70px;

            @media (min-width: 767px){
                height: 50vh;
                padding-bottom: 90px;
            }
            
            .container {
                align-items: flex-end;                
            }
        }
        
        // ambigua
        &.height-100vh {
            height: auto!important;
            aspect-ratio: 3 / 4;

            @media (min-width: 768px){
                height: 80vh!important;
                aspect-ratio: inherit;
            }

            @media (min-width: 992px){
                height: 100vh!important;
            }

            .container {
                align-items: center;
            }
        }

        /*.is-editmode & {
            height: 20vh!important;
        }*/

        &.with-discover {
            .discover {
                display: inline-block;
            }
        }
    }

    &__content {
        position: relative;
        z-index: 10;

        color: white;

        .no-shade & {
            color: var(--color-text);
        }

        h1 {
            line-height: 1.2;            
            margin-bottom: 10px;

            @media (min-width: 992px){
                margin-bottom: 30px;
            }
        
        }

        .text-hero {
            font-size: 24px;
            font-weight: 400;
            line-height: 1.21;
            margin-bottom: 50px;
        }

        .subtitle {
            max-width: 950px;
            margin: auto;
            font-weight: 600;
            //line-height: 1.14;

            @media (max-width: 767px){
                font-size: 22px;
            }
        }

        .text-design {
            font-weight: 300;

            strong {
                font-weight: bold;
            }
        }
    }

    &__tag {
        @extend .g-news__tag;
        color: white;
        position: absolute;
        bottom: 60px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 10;
        border-bottom: none;
    }
}
