:root {
  --btn-font-size: 16px;
  --color-indoor-rgb: 17,44,78;
  --color-outdoor-rgb: 222,68,31;
}

.g-btn {
    color: var(--color-primary);
    font-size: 16px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-transform: uppercase;
    text-decoration: none;
    position: relative;
    cursor: pointer;

    span {
        position: relative;
        z-index: 1;
    }

    &:after {
        content: '';
        position: absolute;
        bottom: -2px;
        left: -5px;
        width: 20px;
        height: 10px;
        background-color: #dedee0;
        z-index: 0;
    }

    &:hover {
        //width: 100%;
    }

    &__white {
        color: white;

        &:after {
            background-color: #dedee0;
        }
    }
}

// Codyhouse custom

.btn {
    font-size: 14px;
    font-weight: bold;
    line-height: 1.4;
    letter-spacing: normal;
    padding: 0px;

    span {
        display: block;
        padding: 5px 30px;

        @media (min-width: 992px){
            padding: 9px 30px;
        }
    }

    &--primary {
        border-color: var(--color-borders);
        color: var(--color-text);

        [data-theme=outdoor] &,
        [data-theme=indoor] & {
            //color: var(--color-primary);
            border-color: var(--color-primary);
        }

        span {
            margin: 2px;
            background-color: transparent;
            transition: background-color .25s ease-in-out 0s, color .25s ease 0s;
        }

        &:hover {
            span {
                color: var(--color-neutro);
                background-color: var(--color-borders);

                [data-theme=outdoor] &,
                [data-theme=indoor] & {
                    background-color: var(--color-primary);
                }
            }
        }
    }

    &--default {
        background-color: transparent;
        border-color: var(--color-borders);
        color: var(--color-text);

        span {
            display: block;
            background-color: transparent;

            transition: all .25s ease 0s;
        }

        &:hover {
            span {
                color: black;
            }
        }
    }

    &--secondary {
        border-color: var(--grey-lg);
        color: white;

        transition: border .25s ease 0s;
        
        span {
            display: block;
            margin: 2px;
            background-color: var(--grey-lg);
            opacity: .6;
            padding: 5px 30px;

            transition: opacity .25s ease 0s;

            @media (min-width: 992px){
                padding: 9px 30px;
            }
        }

        &:hover {
            border-color: rgba(109,109,122,.6);

            span {
                opacity: .4;
            }
        }
    }

    &--map,
    &--link {
        position: relative;
        text-decoration: none;
        font-size: 14px;
        font-weight: 400;
        //font-family: var(--font-primary);        
        display: inline-block;
        overflow: hidden;

        &:after,
        &:before {
            content: '';
            width: 100%;
            height: 2px;
            position: absolute;
            bottom: 0;
            left: 0;
            transition: transform .25s ease 0s;
        }

        &:before {
            background-color: var(--color-primary);
            transform: translateX(0);
        }

        &:after {
            background-color: var(--color-primary);
            opacity: .4;
            z-index: 10;
            transform: translateX(-100%);
        }

        &:hover:before {
            transform: translateX(100%);
        }
        &:hover:after {
            transform: translateX(0);
        }

        &.white {
            color: white;
            //border-color: white;

            &:before {
                background-color: white;
            }
            &:after {
                background-color: rgba(255,255,255,.6);
            }
        }
    }

    &--touch {
        background-color: transparent;
        border: 2px solid var(--grey-sm);
        font-size: 14px;
        width: auto;
        text-align: center;
        text-decoration: none;
        display: inline-block;

        transition: border-color .25s ease-in-out 0s;

        &:hover {
            //border-color: rgba(240,238,236,.8);            

            > span {
                background-color: rgba(255,255,255,.6);
            }
        }

        span {
            margin: 2px;
            text-transform: capitalize;
            font-weight: bold;
            display: block;
            padding: 9px 30px;
            color: var(--grey-sm);
            background-color: rgba(255,255,255,.3);
            transition: background-color .25s ease-in-out 0s;
        }

        &.invert {
            border-color: white;
            
            span {
                color: white;
                background-color: rgba(240,238,236, .6);
                
                transition: background-color .25s ease 0s;
            }

            &:hover {
                span {
                    background-color: rgba(240,238,236, .4);
                }
            }
        }
    }
}

button.btn--link {
    border: none;
    background: none;
}

// Bottone senza span
.btn-wy {
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    line-height: 2;
    padding: 9px 30px;
    text-decoration: none!important;
    color: var(--grey-lg);
    box-shadow: inset 0 0 0 2px white;
    transition: background .25s ease-in-out 0s;

    .bg--grey & {
        box-shadow: inset 0 0 0 2px var(--grey-sm);
    }
    .bg--orange & {
        box-shadow: inset 0 0 0 2px var(--color-outdoor);
    }
    .bg--blue & {   
        box-shadow: inset 0 0 0 2px var(--color-indoor);
    }
    .bg--default & {
        box-shadow: inset 0 0 0 2px var(--color-neutro);
    }

    &.primary {
        border: 2px solid var(--color-neutro);
        background-color: rgba(#a6a6ba,.4);

        &:hover {
            background-color: rgba(#a6a6ba,.2);
        }
    }

    &.secondary {
        border: 2px solid white;
        background-color: rgba(255,255,255,.4);

        &:hover {
            background-color: rgba(255,255,255,.2);
        }
    }

    &.indoor {
        color: white;
        border: 2px solid var(--color-indoor);
        background-color: rgba(var(--color-indoor-rgb),.4);

        &:hover {
            background-color: rgba(var(--color-indoor-rgb),.2);
        }
    }

    &.outdoor {
        color: white;
        border: 2px solid var(--color-outdoor);
        background-color: rgba(var(--color-outdoor-rgb),.4);

        &:hover {
            background-color: rgba(var(--color-outdoor-rgb),.2);
        }
    }
}

