
.collection-list {
    &__link {
        position: absolute;
        z-index: 5;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }

    &__item {
        // height tablet
        @media (max-width: 1024px) and (min-width: 767px){
            height: 340px;
        }

        // Only projects
        .is-designer &,
        .is-progetto & {
            @media (min-width: 1440px) {
                min-height: 475px;
            }
        }

        // Inner content box
        .inner {
            position: relative;
            text-align: center;
            display: flex;
            width: 100%;
            height: 100%;

            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 1;
                background-color: rgba(0,0,0,.3);
    
                transition: background-color .25s ease-in-out 0s;
            }

            // fix
            img {
                min-height: 100%;
            }
        }

        // title and button link
        h5,
        .btn--link {
            position: absolute;
            z-index: 5;
            color: white;
        }

        h5 {
            font-weight: 400;
            top: 50%;
            left: 0;
            right: 0;
            transform: translateY(-50%);
        }

        .btn--link {
            bottom: 0px;
            left: 50%;
            display: inline-block;
            border-color: white;
            opacity: 0;
            transform: translateX(-50%);            
            transition: all .25s ease-in-out 0s;
        }

        &:hover {
            &:after {
                [data-theme=indoor] & {
                    background-color: rgba(var(--color-indoor-rgb),.8);
                }
                [data-theme=outdoor] & {
                    background-color: rgba(var(--color-outdoor-rgb),.8);
                }
            }

            .btn--link {
                opacity: 1;
                bottom: 30px
            }
        }
    }
}