
.pagination {
    display: flex;
    justify-content: center;
    align-items: center;

    li {
        margin: 0 15px;
    }

    .page-link {
        @extend .btn--link;
    }
}