
.g-cta {
    h3 {
        font-weight: 400;
        margin-bottom: 10px;

        @media (min-width: 992px){
            margin-bottom: 20px;
        }

        strong {
            font-weight: 700;
        }
    }

    &__content {
        display: flex;
        align-items: center;
        height: auto;
        padding: var(--space-md) 0;

        @media (min-width: 992px) {
            height: 472px;
        }
    }

    &.with-foot {
        position: relative;

        .bg--white {
            top: -60px;
            z-index: 10;
        }

        &:after {
            position: absolute;
            bottom: 0;
            left: 0;
            content: '';
            width: 100vw;
            height: 110px;
            background-color: var(--color-primary);
        }
    }

    &__buttons {
        display: flex;
        justify-content: flex-start;
        margin: 0 -15px;

        @media (max-width: 992px) {
            margin-top: 30px;
        }

        @media (max-width: 767px){
            flex-direction: column;
        }

        @media (min-width: 992px){
            margin-top: 60px;
            justify-content: space-between;
        }

        > div {
            padding: 0 15px;

            @media (max-width: 767px){
                &:first-child {
                    margin-bottom: 30px;
                }
            }
        }    

        .btn--touch {
            
            @media (max-width: 767px){
                width: 100%;

                > span {
                    padding-left: 0;
                    padding-right: 0;
                }
            }

            @media (min-width: 992px){
                width: auto;
                min-width: 345px;
                
                &:nth-child(2) {
                    margin-left: 30px;
                }
            }
        }
    }

    &__cols {
        flex-direction: column;
        
        @media (min-width: 767px){
            flex-direction: row;

            .items-end {
                text-align: right;
            }
            .items-start {
                text-align: left;
            }
        }
    }

    &__item {

        &:first-child {
            border-bottom: none;
        }
      
        &.with-border-left {    
            @media (min-width: 767px){
                border-left: 1px solid var(--color-borders);
            }
        }

        a {
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }

        @media (max-width: 767px){
            text-align: center;
            align-items: center;
            
            &:first-child {
                border-bottom: 1px solid rgba(136,151,160,0.3);
            }

            &.justify-end,
            &.justify-start {
                justify-content: center;
            }
        }
    }
}
