// Link brick content
.toolbox-content {
    &.wysiwyg { 
        a {            
            color: #1a1a1a;
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }

        a.btn--link {
            text-decoration: none;
        }

        em {
            font-style: italic;
        }

        ul {
            list-style: disc;
        }

        ol {
            list-style: decimal;
        }

        ul,ol {
            margin-left: 18px;
            margin-bottom: 30px;
            line-height: 1.4;
        }
        h2, h3, h4 {
            margin-top: 0;
        }
    }
}

// Link brick download
.toolbox-download {
    ul {
        display: flex;
        flex-wrap: wrap;
        //flex-direction: column;

        /*@media (min-width: 992px) {
            flex-direction: row;
        }*/

        li {
            list-style: none;
            flex: 0 0 50%;
            min-width: 50%;
            margin-bottom: 30px;
        }
    }
}

.toolbox-spacer {
    > span {
        display: block;

        &.spacer-50 {
            height: 30px;

            @media (min-width: 992px){
                height: 50px;
            }
        }
        &.spacer-60 {
            height: 40px;

            @media (min-width: 992px){
                height: 60px;
            }
        }
        &.spacer-100 {
            height: 50px;

            @media (min-width: 992px){
                height: 100px;
            }
        }
    }
}

.toolbox-container {
    .max-width-full {
        margin-left: 0;
        margin-right: 0;
        padding: 0 15px;
        width: 100%!important;

        .toolbox-element {
            margin: auto;
        }

        &.int-width-xl {
            .toolbox-element {
                max-width: var(--max-width-xl);
            }
        }
        &.int-width-lg {
            .toolbox-element {
                max-width: var(--max-width-lg);
            }
        }
        &.int-width-md {
            .toolbox-element {
                max-width: var(--max-width-md);
            }
        }
        &.int-width-sm {
            .toolbox-element {
                max-width: var(--max-width-sm);
            }
        }
    }
}

.toolbox-image {
    margin-bottom: 30px;
}

// video 16/9
.toolbox-video {
    div.media-wrapper,
    div.player {
        --aspect-ratio: calc(16/9);
        position: relative;
        height: 0;
        padding-bottom: calc(100%/(var(--aspect-ratio)));

        > iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}

// Video data asset custom
.toolbox-video[data-type=asset] {
    .media-wrapper {
        height: auto;
        padding-bottom: 0;

        video {
            margin-bottom: 30px;
        }
    }
    .media-wrapper .player {
        position: static;
        padding-bottom: 0;
        height: auto!important;
    }
}