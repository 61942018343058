
// trick for grid problem with isotope
.grid {

}

.grid__col {
    min-width: calc( 100% - 0.01px - var(--gap, 0.75em));

    @media (min-width: 48rem){
        min-width: calc( 50% - 0.01px - var(--gap, 0.75em));
    }
    
    @media (min-width: 64rem){
        min-width: calc( 25% - 0.01px - var(--gap, 0.75em));
    }
     
}