
.modal {
    //background-color: rgba(255,255,255,.95);
    overflow: auto;

    @media (max-width: 767px){
        padding: 0;
    }

    &__title {
     margin-bottom: 30px;
    }

    &__close-btn {
        position: fixed;
        top: 30px;
        right: 30px;
        z-index: 100;
        cursor: pointer;

        @media (max-width: 767px){
            top: 15px;
            right: 15px;
            //display: none;
        }
    }

    .modal__content {
        background-color: white;
        position: relative;
        overflow: inherit;

        // modal image with marker
        #modal-image & {
            overflow: hidden;
        }

        img {
            display: flex;
        }
    }

    .is-prodotto & {
        img {
            width: auto;
        }

        .modal__content {
            background: transparent;
        }
    }

    &__form {
        .modal__content {
            background-color: white!important;
        }

    }    
}

// Modal image carousel product / project
#modal-image {
    .modal__content {
        overflow: hidden;
        max-width: 90%;
        max-height: 90%;

        // control image resize
        /*picture > img {
            max-width: inherit;
            height: 60vh;

            @media (min-width: 992px){
                height: 80vh;
            }
        }*/
    }
}