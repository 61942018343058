.ext-tabs-relative {
    align-items: center;
}

.ext-tabs-col {
    overflow-x: auto;
    align-items: center;
}

.ext-tabs-cont {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-end;
    overflow-x: auto;
    position: relative;

    &::-webkit-scrollbar {
        width: 10px;
        height: 2px;
    }
 
    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }
    
    &::-webkit-scrollbar-thumb {
        background-color: var(--color-text);
        outline: 1px solid var(--color-borders);
    }
    
    //&:after {
    //    display: none;
    //    content: '';
    //    border-bottom: 1px solid var(--color-borders);
    //    position: absolute;
    //    width: 2000px;
    //    isolation: isolate;
    //    bottom: 0;
    //    right: -2000px;
    //    opacity: .4;    
    //}

    @media screen and (max-width: 992px){
        width: auto;
        display: grid;
        grid-auto-flow: column;
        grid-auto-columns: minmax(140px, 180px);
        scroll-snap-type: x mandatory;
        align-items: flex-end;
        justify-content: flex-start;
        
        &:after {
            display: none;
        }
    }
}

button.ext-tab-btn {
    font-size: 1rem;
    font-weight: 700;
    background-color: transparent;
    padding: 0;
    z-index: 1;
    position: relative;
    cursor: pointer;
    text-align: left;
    border: none;
    appearance: none;
    border-bottom: 1px solid  var(--color-borders);
    transition: all .25s ease-in-out;
    color: var(--color-text);
    opacity: .4;
    cursor: pointer;
    padding-right: 10px;
    padding-bottom: 5px;
    line-height: 1.1;
    scroll-snap-align: start; /* Mantiene i bottoni allineati durante lo scroll */

    @media screen and (min-width: 992px){
        padding-right: 2rem;
    }

    &[disabled]{
        visibility: hidden;
        cursor: none;
    }

    &[aria-selected="true"] {
        opacity: 1;
    }

    &:focus,
    &:hover {
        opacity: 1;
    }
}

div.ext-tab-panel[role="tabpanel"] {
    position: relative;
    z-index: 2;

    &.is-hidden {
        display: none;
    }

    &:focus {
        //outline: 1px dotted var(--color-contrast-low);
    }
}

.ext-tab-panel {
    .material__types {
        height: auto;
        min-height: 240px;
        overflow: hidden;

        @media (min-width: 992px){
            min-height: 253px;

            .types__title {
                min-height: 51px;
            }
        }

        &.all-results {
            height: auto!important;
        }
    }
}

.ext-row-trigger {
    margin-top: 1rem;
    display: flex;
    justify-content: flex-end;
}

.ext-panel-trigger {
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: 1rem;
    font-weight: 400;
    color: var(--color-text);
    appearance: none;
    border: none;
    background: none;
    padding: 0;
    cursor: pointer;

    &.is-open {
        svg {
            .icon__group > *:first-child,
            .icon__group > *:last-child {
                transform: translateY(0px) rotate(180deg);
            }
        }   
    }

    svg {
        stroke-width: 2px;
    }
}


@media screen and (min-width: 992px){
    //.ext-tabs-cont {
    //    &:after {
    //        display: block;
    //    }
    //}
}

.ext-grid-trigger {
    width: 100%;
    position: absolute;
    bottom: -45px;
    right: 0;
}
