// --------------------------------

// (START) Global Editor code https://codyhouse.co/ds/globals/forms

// --------------------------------

:root {
  --form-control-padding-x: var(--space-sm);
  --form-control-padding-y: var(--space-xs);
  --form-control-radius: 0.25em;
}

.form-control { // basic form element style
  border: 2px solid var(--color-contrast-low);

  &:focus {
    outline: none;
    border-color: var(--color-primary);
    --color-shadow: hsla(var(--color-primary-h), var(--color-primary-s), var(--color-primary-l), 0.2);
    box-shadow: 0 0 0 3px var(--color-shadow);
  }
}

.form-control[aria-invalid="true"] {
  border-color: var(--color-error);

  &:focus {
    --color-shadow: hsla(var(--color-error-h), var(--color-error-s), var(--color-error-l), 0.2);
    box-shadow: 0 0 0 3px var(--color-shadow);
  }
}

.form-control[disabled], .form-control[readonly] {
  cursor: not-allowed;
}

.form-legend {}

.form-label {
  font-size: var(--text-sm);
}


.error-message p{
  background: #e94b35;
  color: #fff;
  font-size: 1.4rem;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border-radius: .25em;
  padding: 16px;
}

.success-message p{
  background: lightgreen;
  color: #fff;
  font-size: 1.4rem;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border-radius: .25em;
  padding: 16px;
}
// --------------------------------

// (END) Global Editor Code

// --------------------------------