.full {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.style-quickship {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 3px;
    color: var(--color-quickship);
}

// grid per equal height

.d-grid {
    display: grid;
    column-gap: 30px;
    row-gap: 30px;
    
    &.col--3,
    &.col--4 {
        grid-template-columns: 1fr;

        @media (min-width: 767px) and (max-width: 992px){
            grid-template-columns: 1fr 1fr;
        }
    }

    &.col--2 {
        grid-template-columns: 1fr;

        @media (min-width: 767px){
            grid-template-columns: 1fr 1fr;
        }
    }

    &.col--3 {
        @media (min-width: 992px){
            grid-template-columns: 1fr 1fr 1fr;
        }
    }

    &.col--4 {
        @media (min-width: 992px){
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }
    }
}
