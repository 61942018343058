
.realizzazioni {
  &__right__grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 300px 300px 300px;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    grid-auto-flow: column;
  }

  .item {
    align-self: start;
    background-color: grey;
    //background-image: none!important;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100%;

    .text-component {
      //opacity: 0;
      color: white;

      h4 {
        color: white;
      }
    }
  }
  .item-2 {
    grid-column: 2 / 4;
    grid-row: 1;
    background-color: red;
  }
  .item-3 {
    grid-column: 1;
    grid-row: 2 / 4;
    background-color: green;
  }
}