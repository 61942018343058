
// Titles type

.title {
    display: inline-block;
    font-family: var(--font-primary);

    &.lg {
        font-size: 50px;
    }

    &.md {
        font-size: 38px;
    }

    &.sm {
        font-size: 24px;
    }

    &.xs {
        font-size: 20px;
    }

    &.ls-xl {
        letter-spacing: 7.13px;
    }

    &--grey {
        font-weight: 300;
        line-height: 1;
        letter-spacing: 2.28px;
        color: #6d6d7a;
    }

    &--line {
        font-weight: 300;
        line-height: 1;
        text-align: center;
        letter-spacing: 3px;
        position: relative;

        &:before {
            content: '';
            position: absolute;
            bottom: 4px;
            right: 105%;
            width: 999px;
            height: 3px;
            background-color: var(--color-primary);
        }
    }
}

.h1,.h2,.h3,.h4,.h5,.h6,
h1,h2,h3,h4,h5,h6 {
    display: block;

    // control title on hero
    .hero__content &,
    .bg--primary & {
      color: white;

      // test hero senza immagine
      .no-shade & {
          color: var(--color-text);
      }
    }

    &.black {
        font-weight: 900;
    }

    &.bold {
        font-weight: 700;
    }
}

h1,.h1 {
    font-size: 44px;

    @media (min-width: 767px){
        font-size: var(--text-xxxl); // font-size 70px
    }
}

h2,.h2 {
    font-size: 35px;

    @media (min-width: 767px) {    
        font-size: var(--text-xxl); // font-size 55px
    }
}

h3,.h3 {
    font-size: 31px;

    @media (min-width: 767px) {
        font-size: var(--text-xl); // font-size 45px
    }
}

h4,.h4 {
    font-size: 24px;
    
    @media (min-width: 767px) {
        font-size: var(--text-lg); // font-size 35px
    }
}

h5,.h5 {
    font-size: 22px;

    @media (min-width: 767px) {
        font-size: var(--text-md); // font-size 28px
    }
}

// Weight custom
h1,.h1,
h2,.h2,
h3,.h3,
h4,.h4,
h5,.h5,
h6,.h6 {
    font-weight: 700;
}

