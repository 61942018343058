
.is-store-locator .hero {
    @media (max-width: 992px){
        flex-direction: column;
        height: auto;

        .container {
            min-height: 50vh;
        }
    }
    
    h1 {
        //max-width: 960px;

        @media (min-width: 992px){
            padding: 0 15px;
        }
    }

    // flex container
    .container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.hero__search {
    margin-top: 90px;
    
    h5 {
        text-align: center;
        margin-bottom: 30px;
    }

    input[type=search] {
        border: none;
        padding: 5px 0;
        //font-family: var(--font-secondary);
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 2.6px;
        background: none;
        width: 100%;
        text-transform: uppercase;
        color: rgba(35,46,53,.6);
        border-radius: 0;
        border-bottom: 1px solid rgba(35,46,53,.4);
        padding-right: 20px;

        appearance: none;
        -webkit-appearance: none;

        @media (min-width: 992px) {
            font-size: 16px;
            padding: 9px 0;
            letter-spacing: 3px;
        }

        @media (max-width: 767px) {
            padding-top: 0;
        }

        &:focus {
            outline: 0;
            border-color: rgba(35,46,53,.6);
        }

        &::placeholder {
            color: var(--grey-lg);
        }
    }
}

// Content store
.store-content {
    display: flex;
    flex-direction: column;
    max-width: 2500px;
    margin: auto;

    @media (min-width: 992px){
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: row;
    }

    &__part {
        width: 100vw;

        // part with spot
        &:nth-child(1) {
            border-bottom: 1px solid var(--color-borders);
           
            @media (min-width: 992px){
                border: none;
            }
        }

        @media (min-width: 992px){
            width: 50vw;
        }

        .on-detail & {
            height: auto;
            position: relative;

            @media (min-width: 992px) {
                height: 100vh;
            }
        }

        // Google map with api
        #map {
            width: 100%;
            height: 100%;
        }

        &.store-list {
            padding: 30px 15px;

            @media (min-width: 992px) {  
                padding: 30px;
            }
        }

        &.store-map {
            display: none;
            position: relative;
            min-height: 100vh;

            .map-is-visible & {
                display: inline-block;
                height: 300px;
            }

            @media (min-width: 992px) {
                display: block;
                top: 0;
                height: 100vh;
                position: static;

                border-left: 1px solid var(--color-borders);
                
                &.is-sticky {
                    position: fixed;
                    top: 0;
                    left: 50vw;
                }                
            }
        }

        .back-link {
            position: static;
            bottom: 60px;
            left: 30px;

            @media (min-width: 992px) {
                position: absolute;
            }
        }

        // autorizzato outdoor
        .authorized {
            margin-top: 30px;

            label {
                margin-bottom: 10px;
            }

            // svg logo outdoor
            svg {
                max-width: 150px;
                fill: var(--color-outdoor);
            }
        }
    }
}

// Lista box store
.store-list {
    h5 {
        margin-bottom: 15px;
    }

    p,
    label,
    a:not(.btn--link) {
        text-decoration: none;
    }

    a:not(.btn--link):hover {
        text-decoration: underline;
    }

    p {
        margin-bottom: 10px;
        font-size: 1rem;
        line-height: 1.33;

        > i {
            font-size: 20px;
            color: var(--color-text);
        }
    }

    label {
        margin-top: 15px;
        margin-bottom: 10px; 
        font-weight: 600;
        display: block;
        line-height: 1;

        &.no-margin {
            margin-top: 0; 
        }
    }

    &__item {
        border: 1px solid var(--color-borders);
        padding: 30px;
        margin-bottom: 30px;
        position: static!important;

        .top {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            
            @media (min-width: 1200px) {
                flex-direction: row;
                column-gap: 30px;
            }
        }

        /*.top > .left {
            @media (min-width: 1200px){
                min-width: 60%;
            }
        }*/

        .bottom {
            border-top: 1px solid var(--color-borders);
            margin-top: 30px;
            padding-top: 10px;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.store-products {
    margin-top: 60px;

    h4 {
        margin-bottom: 30px;
    }

    .product-list {
        
        .slick-list {
            margin-top: 30px;
            margin-bottom: 60px;
            margin-left: 15px;
            position: relative;

            @media (min-width: 992px){
                margin-left: 30px;
            }   

            &:after {
                content: '';
                background-image: linear-gradient(to left, rgb(255, 255, 255) 30%, rgba(255, 255, 255, 0) 100%);
                position: absolute;
                top: 0;
                right: -30px;
                width: 200px;
                height: 100%;
                z-index: 10;
            }
        }

        .slick-slide {
            margin-right: 30px;
        }

        &__item {
            h3 {
                margin-bottom: 10px;
            }
        } 
    }
}

// Store contacs
.store-contacts {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    row-gap: 10px;
    
    @media (min-width: 1200px) {
        min-width: 300px;
    }

    &__icon {
        font-size: 1rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        column-gap: 10px;

        > a {
            line-height: 1.4;
        }
    }
}