
.logo--svg {
    width: 150px;

    .g-footer & {
        width: 180px;
    }

    @media (min-width: 992px){
        width: 214px;

        .g-footer & {
            width: 256px;
        }
    }

    .lettering {
        fill: var(--color-text);

        &.outdoor {
            display: none;
        }
    }

    .absolute-header & {
        .lettering {
            fill: white;
        }
    }

    .menu-is-open & {
        .lettering {
            fill: var(--color-text);
        }
    }

    [data-theme=indoor] & {
        .menu-is-open .lettering,
        .lettering {
            fill: var(--color-indoor);
        }
    }

    [data-theme=outdoor] & {
        .menu-is-open .lettering,
        .lettering {
            fill: var(--color-outdoor);

            &.outdoor {
                display: inline-block;
            }
        }
    }
}