:root {
    --color-quickship: #cf2e0a;
    --color-borders: #8897A0;
}

* {
  outline: none;
}

html {
  overflow-x: hidden;
}

[data-theme],
body {
    background-color: var(--color-neutro);
}

body {
    overflow-x: hidden;
    
    &.is-editmode {
        min-height: inherit!important;
    }
}

img, 
video, 
svg {
  max-width: 100%;
  height: auto;
}

small {
    font-size: 80%;
}
