// --------------------------------

// (START) Global Editor code https://codyhouse.co/ds/globals/typography

// --------------------------------

:root {
    // font family
    --font-primary: Merriweather, serif;
    --font-secondary: Nunito Sans, sans-serif;

    // body font size
    --text-base-size: 1.125em;

    // type scale
    --text-scale-ratio: 1.25;
    --text-xs: calc((1.275em / var(--text-scale-ratio)) / var(--text-scale-ratio));
    --text-sm: calc(var(--text-xs) * var(--text-scale-ratio));
    --text-md: calc(var(--text-sm) * var(--text-scale-ratio) * var(--text-scale-ratio));
    --text-lg: calc(var(--text-md) * var(--text-scale-ratio));
    --text-xl: calc(var(--text-lg) * var(--text-scale-ratio));
    --text-xxl: calc(var(--text-xl) * var(--text-scale-ratio));
    --text-xxxl: calc(var(--text-xxl) * var(--text-scale-ratio));

    // line-height
    --body-line-height: 1.8;
    --heading-line-height: 1.4;

    // capital letters - used in combo with the lhCrop mixin
    --font-primary-capital-letter: 1;
    --font-secondary-capital-letter: 1;

    // text color
    --color-text: #232E35;
  }

  @supports(--css: variables) {
    :root {
        --text-base-size: 1.125em;

        @include breakpoint(md) {
            --text-scale-ratio: 1.25;
        }
    }
  }

  body {
    font-family: var(--font-secondary);
    font-weight: 300;
    line-height: var(--body-line-height);
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: var(--font-primary);
    font-weight: 900;
    line-height: var(--heading-line-height);
  }

  // font family
  .font-primary { font-family: var(--font-primary);}
  .font-secondary { font-family: var(--font-secondary);}

// --------------------------------

// (END) Global Editor Code

// --------------------------------

body {
    font-size: var(--text-base-size);
    color: var(--color-text);
}


p {
  //color: var(--color-text);
  margin-bottom: .5rem;
  margin-top: 0;
}

// link style
a, .link {
    color: var(--color-text);

    .g-footer__bottom & {
        color: var(--color-bottom-footer);
    }
}

mark {
  background-color: alpha(var(--color-accent), 0.2);
  color: inherit;
}

.text-component {
  --line-height-multiplier: 1;
  --text-vspace-multiplier: 1;

  blockquote {
    padding-left: 1em;
    border-left: 4px solid var(--color-contrast-low);
  }

  hr {
    background: var(--color-contrast-low);
    height: 1px;
  }

  figcaption {
    font-size: var(--text-sm);
    color: var(--color-contrast-medium);
  }
}

.article.text-component { // e.g., blog posts
  --line-height-multiplier: 1.2; // increase article line-height
  --text-vspace-multiplier: 1.4; // increase vertical spacing
}

strong {
  font-weight: 700;
}
