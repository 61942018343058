/* clears the 'X' from Internet Explorer */
input[type=search]::-ms-clear {  display: none; width : 0; height: 0; }
input[type=search]::-ms-reveal {  display: none; width : 0; height: 0; }

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }

// Reserved area
.form-group {
    .form-control {
        border-radius: 0px;
    }

    &.custom-select {
        position: relative;
        z-index: 1;

        &:after {
            content: '\f078';
            font-family: 'Font Awesome 5 Pro';
            font-size: 14px;
            color: var(--grey-lg);
            position: absolute;
            right: 0;
            //bottom: 12px;
            z-index: -1;
    
            @media (min-width: 992px) {
                bottom: 35px;
            }
        }
    }

    &.privacy-item {
        font-size: 14px;

        label {
            font-weight: bold;
            font-size: 14px;
        }        

        /*[type=radio],
        [type=checkbox] {
        
        }*/

        [type=submit] {
            margin-top: 30px;
        }
    }

    &.with-tooltip {
        .tooltip-trigger {
            position: absolute;
            right: 0;
            top: 0;
        }
    }

    .privacy-item__title {
        font-weight: bold;
    }

    [data-extera-gdpr],
    .privacy-item__title,    
    .privacy-item__text {
        display: inline-block;
        margin-bottom: 10px;
    }

    .alert {
        position: absolute;
        font-size: 12px;
        bottom: 7px;
        right: 0;
        opacity: .8;
        margin-bottom: 0;
        padding: 3px 5px;
        margin-top: 0;
    }

    .form-check {
        display: flex;
        align-items: center;
    }
    .form-check-label {
        margin-bottom: 0;
        margin-left: 5px;
        font-size: 14px;
    }
}

// Form content
.formbuilder {

    .alert {
        position: absolute;
        font-size: 12px;
        bottom: 7px;
        right: 0;
        opacity: .8;
        margin-bottom: 0;
        padding: 3px 5px;
        margin-top: 0;
    }

    .form-check {
        margin-bottom: var(--space-md);
        
        label {
            display: inline-block;
            font-size: 14px;
            margin-left: 5px;
        }
    }

    input[name$='[exteraHp]'] {
        display: none;
    }

    .privacy-item {
        font-size: 14px;

        .checkbox-custom {
            //display: none;
        }

        .help-block {
            margin-left: 5px;
            font-size: 14px;
        }

        button {
            cursor: pointer;
            padding: 9px 30px;
            margin-top: 30px;
        }
    }

    .checkbox-custom,
    [data-extera-gdpr],
    .privacy-item__title,    
    .privacy-item__text {
        font-size: 14px;
        line-height: 1.2;
        display: inline-block;
        margin-bottom: 10px;
    }

    .privacy-item__title {
        font-weight: bold;
        margin-bottom: 0;
    }
}

// Form modale
.modal__form {
    .error-message p {
        padding: 10px 15px;
        font-size: 16px;
        font-weight: 400;
        border-radius: 0;
    }
}

// Unifico parti identiche
.formbuilder,
.form-group {
    position: relative;

    label {
        display: block;
        font-size: 18px;
        margin-bottom: 10px;
    }

    select,
    input[type=text],
    input[type=email],
    input[type=password],
    textarea {
        border: none;
        padding: 9px 0;
        //font-family: var(--font-primary);
        font-size: 16px;
        font-weight: 300;
        background: none;
        width: 100%;
        border-radius: 0;
        border-bottom: 1px solid rgba(35,46,53,.8);
        padding-right: 20px;
        margin-bottom: var(--space-md);
        -moz-appearance: none;
        appearance: none;
        -webkit-appearance: none;

        transition: border-color .25s ease 0s;

        &:focus {
            box-shadow: none;
            border-color: var(--color-text);
            background-color: var(--grey-sm);
            padding-left: 15px;
        }
    }

    button[type=submit] { 
        margin-top: 20px;
    }
}

// custom radio in store locator
.radio--flex {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 30px;
}
.radio--custom {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;

    label {
        margin: 0;
        text-transform: uppercase;
        letter-spacing: 2.6px;
    }
}

// Privacy elements

[data-field-name='terms'] {
    a[data-extera-gdpr],
    p {
        margin-bottom: 0;
    }
}
