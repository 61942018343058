
.g-header {
    background-color: transparent;
    height: 100px;
    box-shadow: none;
    z-index: 10;
    // overflow: hidden;

    &.absolute-header {
        position: absolute;
        top: 0;
        width: 100%;
    }

    .is-prodotto &,
    .is-editmode & {
        position: static;
    }

    .menu-is-open & {
        position: relative;
        height: 100vh;
        background-image: none;
        box-shadow: none;
    }

    &__logo {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }

    &__ar {
        position: relative;
        z-index: 1;

        display: flex;
        column-gap: 15px;
        align-items: center;
        justify-content: flex-start;

        @media (min-width: 992px){
            column-gap: 30px;
        }

        // fix per altezza
        > a {
            display: flex;
        }

        // search + area r
        svg {
            fill: var(--color-text);

            .absolute-header & {
                fill: white;
            }

            .menu-is-open & {
                fill: var(--color-text);
            }
        }
    }

    .container {
        &--top {
            width: calc(100% - 30px);
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            height: 100px;
            position: relative;            
            z-index: 10;
            border-bottom: 1px solid var(--color-borders);

            &:before,
            &:after {
                content: '';
                width: 100%;
                height: 1px;
                background-color: var(--color-borders);
                position: absolute;
                bottom: -1px;
            }

            &:after {
                right: -100%;
            }

            &:before {
                left: -100%;
            }
        }

        &--bottom {
            height: calc(100vh - 100px);
            opacity: 0;
            visibility: hidden;
            overflow: hidden;

            // animation megamenu
            transition: opacity .25s ease-in-out .15s; 

            @media (max-width: 992px) {
                width: 100vw;
                margin-left: 0;
                margin-right: 0;
            }

            .bottom-wrap {
                height: auto;
                overflow-y: auto;
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-start;

                @media (min-width: 992px) and (max-height: 800px) {
                    align-items: center;
                }
                
                @media (max-width: 992px){
                    flex-direction: column;
                    justify-content: flex-start;
                }
            }

            .menu-is-open & {
                opacity: 1;
                visibility: visible;

                @media (max-width: 992px) {
                    overflow-x: hidden;
                    overflow-y: auto;
                }
            }
        }
    }

    &__svg {
        position: absolute;
        bottom: 0;
        right: 0;
        max-width: 520px;
        width: 25vw;
        z-index: 0;
        display: none;

        @media (min-width: 992px){
            display: inline-block;
        }

        path {
            stroke: var(--color-borders);
        }
    }

    &__nav {
        .toggle-nav {
            width: 30px;
            height: 20px;
            outline: none;
            cursor: pointer;
        
            &.is-active {
                padding: 0;
            }

            .absolute-header & {
                background-color: transparent;
            }
        }
    }
}

.menu-is-open {
    height: 100vh;

    &.absolute-header {
      .g-header {
        opacity: 1;
      }
    }
}

// toggle nav revisited
.toggle-nav { 
    > span {
        display: block;
        width: 30px;
        height: 4px;
        margin: 4px 0;
        background-color: var(--color-text);
        transition: all .3s ease;

        .absolute-header & {
            background-color: white;
        }

        .menu-is-open & {
            background-color: var(--color-text);
        }
    }

    span:nth-child(1) {
        .menu-is-open & {
            transform: translateY(0.5rem) rotateZ(-45deg);
        }
    }
    span:nth-child(2) {
        .menu-is-open & {
            width: 0;
        }
    }
    span:nth-child(3) {
        .menu-is-open & {
            transform: translateY(-0.5rem) rotateZ(45deg);
        }
    }
}