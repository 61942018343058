
.swiper-slide {
    /* Center slide text vertically */
    display: flex;
    //flex-direction: column;
    justify-content: center;
    align-items: center;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    position: relative;
    overflow: hidden;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.55;
        background-image: linear-gradient(to bottom, #000000, rgba(0, 0, 0, 0));
        z-index: 5;
    }

    &.no-shade:before {
        display: none;
    } 

    /*&.with-video {
        @media (min-width: 992px){
            background-image: none!important;
        }
    }*/

    &__content {
        text-align: center;
        padding: 0 15px;
        position: relative;
        z-index: 10;

        h2,
        h3,
        h4,
        h5,
        h6 {
            color: white;
        }

        h3,h2 {
            margin-bottom: 20px;
            //line-height: 1;
        }

        // subtitle
        h5 {
            font-size: 18px;

            @media (min-width: 767px){
                font-size: var(--text-md);
            }
        }

        .lead {
            color: white;
            margin-top: 30px;
        }

        .btn--touch {
            //border-color: var(--grey-lg);
            margin-top: 60px;
            
            > span {
                //color: var(--grey-lg);
            }

            [data-theme=indoor] & {
                border-color: var(--color-indoor);
                
                > span {
                    color: var(--color-indoor);
                }
            }
            [data-theme=outdoor] & {
                border-color: var(--color-outdoor);
                
                > span { 
                    color: var(--color-outdoor);
                }
            }
        }
    }

    .full {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        width: 100%;
        height: 100%;
    }

    &__video {
        position: absolute;
        right: 0;
        bottom: 0;
        min-width: 100%;
        min-height: 100%;
        display: none;   

        @media (min-width: 992px){
            display: block;
        }
    }

    // hover all
    &:hover {
        .btn--touch > span {
            background-color: rgba(255,255,255,.6);
        }
    }
}

.swiper-button-prev,
.swiper-button-next {
    right: 50%;
    left: auto;
    margin-right: -22px;
    transform: rotate(90deg);

    @media (min-width: 992px){
        right: 58px;
        margin-right: 0;
    }

    &:after {
        font-size: 13px;
        color: white;
    }
}

.swiper-button-prev {
    @media (max-width: 992px){
        top: auto;
        bottom: 60px;
        margin-top: 0;
    }

    @media (min-width: 992px){
        margin-top: -75px;
    }
}
.swiper-button-next {
    @media (max-width: 992px){
        top: auto;
        bottom: 10px;
        margin-top: 0;
    }

    @media (min-width: 992px){
        margin-top: 50px;
    }
}

.swiper-pagination-fraction {
    position: absolute;
    bottom: 47px;
    top: auto;
    right: 50%;
    left: auto;
    width: 33px;
    margin-right: -25px;
    color: white;
    font-size: 16px;

    @media (min-width: 992px){
        bottom: auto;
        top: 50%;
        right: 58px;
        left: auto;
        margin-right: -4px;
    }
}