
:root {
    --bg-footer: #DCDCDD;
    --color-bottom-footer: #8897A0;
}

.g-footer {
    position: relative;
    background-color: var(--bg-footer);
    border-top: 1px solid var(--color-borders);

    .no-border-footer & {
        border-top: none;
    }

    /*label {
        display: block;
        text-transform: uppercase;
        font-family: var(--font-primary);
        font-weight: 300;
        font-size: 20px;
        line-height: 1;
        color: #28282d;
        margin-bottom: 1px;

        @media (min-width: 992px) {
            font-size: 24px;
            margin-bottom: 10px;
        }
    }*/

    a,
    span,
    p {
        text-decoration: none;
    }

    a:hover {
        text-decoration: underline;
    }

    .g-social {
        display: flex;
        column-gap: 30px;
        margin-bottom: 30px;

        li > a {
            font-size: var(--text-sm);
        }
    }

    &__top {
        padding: 30px 0;

        @media (min-width: 992px){
            padding: 60px 0;
        }
    }

    &__bottom {
        font-size: 1em;
        padding: 30px 0;
        border-top: 1px solid rgba(136,151,160,.3);
        color: var(--color-bottom-footer);
    }

    &__info {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;

        span {
            display: flex;

            &:after {
                display: inline-block;
                content: '|';
                padding: 0 15px;

                @media (max-width: 767px){
                    display: none;
                }
            }

            &:last-child:after {
                display: none;
            }

            a {
                margin-bottom: 0.5rem;
            }
        }
    }

    &__logo {
        margin-bottom: 30px;

        svg {
            margin-bottom: 20px;
        }
    }

    &__lang {
        padding: 30px 0;

        ul {
            display: flex;
            column-gap: 10px;
        }

        li a {
            font-size: 20px;

            &.active {
                font-weight: bold;
            }
        }
    }

    &__links {
        display: flex;
        flex-wrap: wrap;
        column-gap: 20px;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;

        li > a {
            text-transform: uppercase;
        }
    }

    &__section{
        p{
            line-height: 1.2;
        }
    }
}
