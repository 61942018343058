:root{
  --btn-font-size: var(--text-sm);
  --btn-font-size-sm: 0.8em;
  --btn-font-size-md: 1.2em;
  --btn-font-size-lg: 1.4em;
  --btn-padding-x: var(--space-md);
  --btn-padding-y: var(--space-sm);
  --btn-radius: 0;
}

.btn {
  background-color: var(--color-contrast-lower);
  color: var(--color-contrast-high);
  cursor: pointer;
  border: 2px solid transparent;
  font-weight: bold;
  text-decoration: none;
  box-shadow: none;
  //font-family: var(--font-primary);
  //text-transform: uppercase;
}

// themes
.btn--primary {
  background-color: transparent;
  color: var(--color-contrast-high);
  border: 2px solid var(--color-contrast-low);

  @include fontSmooth;
}

.btn--subtle {
  background-color: var(--color-contrast-low);
  color: var(--color-contrast-higher);
}

.btn--accent {
  background-color: var(--color-accent);
  color: var(--color-white);
  @include fontSmooth;
}

// feedback
.btn--disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

// size
.btn--sm {
}

.btn--md {
}

.btn--lg {
}
// --------------------------------