
.alert {
    padding-top: var(--form-control-padding-y, 0.5em);
    padding-bottom: var(--form-control-padding-y, 0.5em);
    padding-left: var(--form-control-padding-x, 0.75em);
    padding-right: var(--form-control-padding-x, 0.75em);
    margin-bottom: 30px;

    p {
        margin-bottom: 0!important;
    }
}