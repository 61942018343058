
body {
    &.menu-is-open {
        height: 100vh;
    }
}

.g-nav {
    position: relative;
    z-index: 5;
    padding-top: 12vh;

    @media (min-width: 992px) and (max-height: 800px) {
        padding-top: 0;
    }

    @media (max-width: 992px) {
        width: 100vw;
        margin-bottom: 20px;
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 60px;
    }

    // list
    ul {
        line-height: 1;
    }

    // old style per generazione voci di menù opacizzate
    &__item {
        /*&:not(.opened) {
            a, span {
                opacity: .3;
            }
        }*/
    }

    &__sublistitem,
    &__item {
        position: relative;
        min-width: inherit;
        max-width: inherit;
        margin-bottom: 20px;

        // solo mobile
        @media (max-width: 992px){
            &.test-no-bold {
                margin-bottom: 0;
            }
        }

        transition: all .25s ease-in-out 0s;

        // Only small screen desk and tablet landscape
        @media (min-width: 992px){
            min-width: 230px;
            max-width: inherit;
            margin-bottom: 6.3559322033898304vh;
        }

        // Only desktop
        @media (min-width: 1280px){
            min-width: 350px;
            max-width: 470px;
        }

        > a,
        > span {
            color: var(--color-text);
            display: inline-block;
            text-decoration: none;
            letter-spacing: normal;
            transition: color .5s ease-in-out 0s;

            @media (min-width: 992px) {
                font-size: 25px;
            }

            &:before {
                content: '';
                opacity: 0;
                width: 10px;
                height: 10px;
                border: 1px solid var(--color-borders);
                border-radius: 50%;
            }
        }

        .item-link {
            text-decoration: none;
            font-weight: lighter;
            transition: opacity .25s ease-in-out 0s;

            @media (min-width: 992px) and (max-height: 800px) {
                font-size: 1rem;
            }

            // un pò di opa
            &:hover {
                opacity: .7;
            }
        }

        .item-link.first-level,
        .item-text {
            position: relative;
            font-family: var(--font-primary);
            font-weight: bold;
            font-size: 20px;

            @media (min-width: 992px){
                font-size: 25px;
            }

            &:before {
                position: absolute;
                top: 50%;
                left: -25px;
                margin-top: -5px;
            }

            &.item-text--light {
                font-size: inherit;
                font-weight: lighter;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 5px;
                line-height: 1.87;

                @extend .f-nunito;

                &:before {
                    display: none;
                }

                &:after {
                    content: '';
                    width: 14px;
                    height: 14px;
                    background-size: 14px 14px;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='1rem' fill='%23232E35' viewBox='0 0 320 512'%3E%3Cpath d='M301.7 250.3c3.1 3.1 3.1 8.2 0 11.3l-216 216c-3.1 3.1-8.2 3.1-11.3 0s-3.1-8.2 0-11.3L284.7 256 74.3 45.7c-3.1-3.1-3.1-8.2 0-11.3s8.2-3.1 11.3 0l216 216z'/%3E%3C/svg%3E");
                    opacity: 1;
                    transition: opacity .25s ease-in-out;
                    transform: rotate(90deg);

                    @media (min-width: 992px){
                        opacity: 0;
                        transform: rotate(0deg);
                    }
                }

                &.active:after {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='1rem' fill='%23232E35' viewBox='0 0 384 512'%3E%3Cpath d='M338.1 413.4c3.1 3.1 8.2 3.1 11.3 0s3.1-8.2 0-11.3L203.3 256 349.4 109.9c3.1-3.1 3.1-8.2 0-11.3s-8.2-3.1-11.3 0L192 244.7 45.9 98.6c-3.1-3.1-8.2-3.1-11.3 0s-3.1 8.2 0 11.3L180.7 256 34.6 402.1c-3.1 3.1-3.1 8.2 0 11.3s8.2 3.1 11.3 0L192 267.3 338.1 413.4z'/%3E%3C/svg%3E");
                }

                &:hover:after {
                    @media (min-width: 992px){
                        opacity: 1;
                    }
                }
            }
        }

        // hover css
        &.opened:not(.test-no-bold),
        &:hover:not(.test-no-bold) {
            padding-left: 25px;
            transition: all .25s ease-out 0s;

            > a:before,
            > span:before {
                opacity: 1;
                transition: opacity .25s ease-in-out .1s;
            }
        }

        > a,span {
            cursor: pointer;
        }

        // hover child
        /*&.has-child:hover {
            .no-touchevents & {
                > ul {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }*/

        // list general
        ul {
            position: absolute;
            top: 0;
            left: 100%;
            opacity: 0;
            visibility: hidden;
            min-width: 200px;
            //padding-left: .5vw;

            &.is-visible {
                opacity: 1;
                visibility: visible;
            }

            li {
                @media (min-width: 992px) {
                    margin-bottom: 2.1186440677966103vh;
                }
            }

            li a {
                line-height: 1.87;
                font-weight: normal;
                letter-spacing: normal;

                @media (min-width: 992px) {
                   line-height: 1.4;
                }
            }
        }
    }

     // item li
     &__item {
        // fix last item
        &:nth-last-of-type(3),
        &:nth-last-of-type(2),
        &:nth-last-of-type(1) {
            ul {
                top: auto;
                bottom: 0px;
                li{
                    @media (min-width: 992px) {
                        margin-top: 2.1186440677966103vh;
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    &__sublistitem {
        padding-bottom: 0;
    }
}

// only touch
.g-nav {
    li.has-child ul {
        @media (max-width: 992px) {
            display: none;
            position: static;
        }

        &.is-open {
            opacity: 1;
            visibility: visible;

            @media (max-width: 992px) {
                display: block;
                margin: 20px 0;
            }
        }
    }
}

// Megamenu infos
.g-info {
    width: calc(100vw - 60px);
    height: calc(100vh - 100px);
    overflow: hidden;
    margin: 0 30px;
    position: relative;
    text-align: left;
    z-index: 2;

    @media (min-width: 1440px){
        padding-left: 120px;
    }

    // border left
    @media (min-width: 992px){
        width: auto;
        padding-left: 8vw;
        padding-top: 12vh;
        border-left: 1px solid var(--color-borders);
    }

    @media (max-width: 992px){
        padding-left: 0px;
        padding-top: 30px;
    }

    // custom title g-info
    .h4 {
        margin-bottom: 15px;

        @media (min-width: 992px) {
            margin-bottom: 30px;
        }

        @media (max-width: 992px) {
            font-size: var(--text-sm);
        }
    }

    a {
        font-weight: 300;
        text-decoration: none;
    }

    &__btn {
        display: inline-block;
        border: solid 1px var(--color-text);
        width: auto;
        text-align: center;
        transition: border .25s ease-in-out 0s;

        @media (min-width: 992px) {
            max-width: 200px;
            padding: 0;
            //float: right;
            width: 100%;
        }

        > span {
            display: block;
            margin: 2px;
            padding: 9px 30px;
            background-color: rgba(255,255,255,.6);
            font-weight: bold;
            font-family: var(--font-primary);
            font-size: 14px;
            color: #1a1a1a;

            transition: background-color .25s ease-in-out 0s;
        }

        &:hover {
            border-color: rgba(#6d6d7a,.8);

            span {
                background-color: rgba(255,255,255,.3);
            }
        }
    }

    &__contact {
        display: flex;
        row-gap: 15px;
        flex-direction: column;

        a {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            &:before {
                content: '';
                font-family: 'Font Awesome 5 Pro';
                display: inline-block;
                width: 20px;
                height: 20px;
                margin-right: 15px;

                // rimuovo definitivamente (no bozza)
                display: none;
            }

            &:hover {
                text-decoration: underline;
            }
        }

        // display none actually
        // for link with tel
        a[href*=tel] {
            &:before {
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='phone-alt' class='svg-inline--fa fa-phone-alt fa-w-16' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='currentColor' d='M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z'%3E%3C/path%3E%3C/svg%3E");
            }
        }
        // for link with mailto
        a[href*=mailto] {
            &:before {
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='envelope' class='svg-inline--fa fa-envelope fa-w-16' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='currentColor' d='M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z'%3E%3C/path%3E%3C/svg%3E");
            }
        }
    }

    &__social {
        li {
            display: inline;
            margin-left: 10px;

            &:first-child {
                margin-left: 0;
            }

            > a {
                line-height: 1.36;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    &__newsletter {
        margin-top: 15px;
    }

    &__mobile {
        line-height: 1.56;

        a {
            font-size: 16px;

            &:hover {
                text-decoration: underline;
            }
        }

        > a:nth-child(1) {
            margin-right: 13px;

            &:after {
                content: '|';
                padding-left: 15px;
            }
        }

        ul {
            margin-top: 10px;
            margin-bottom: 30px;
        }
    }
}
