
.main-content {
    min-height: 30vh;
    border-top: 1px solid var(--color-borders);

    // Paragraph
    .lead {
        font-size: 17px;
        line-height: 1.53;

        @media (min-width: 767px){
            font-size: 22px;
        }

        &.dark {
            color: var(--color-text);
        }

        &.light,
        .light & {
            color: white;
        }
    }

    p {
        .is-content & {
            margin-bottom: var(--space-sm);
        }
    }

    dl {
        margin-bottom: var(--space-sm);

        dd {
            line-height: 1.4;
        }
    }
}
