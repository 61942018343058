
.accordion {
    &--materials {
        margin-top: 60px;
    }

    &__item {
        border: none;
        padding-left: 0;
        padding-right: 0;

        h4 {
            .mazzette & {
                padding-bottom: 20px;
                border-bottom: 1px solid var(--grey-md);
            }
        }
    }

    &__header {
        border-bottom: 1px solid var(--grey-md);
        //margin-bottom: 60px;
    }

    &__panel {
        .text-component {
            @extend .lead;  
        }
    }
}

// title material / fabric
.types {
    &__title {
        text-transform: capitalize;
    }
}