.link-download,
.link-with-arrow {
    display: flex;
    align-items: center;
    font-weight: 400;
    text-decoration: none;

    &:after {
        content: '\f175';
        font-family: 'Font Awesome 5 Pro';
        display: inline-block;
        margin-left: 10px;

        .downloads & {
            margin-left: 5px;
        }
    }
}

.link-chevron-down {
    display: flex;
    align-items: center;
    text-decoration: none;

    &.clicked {
        opacity: 0;
    }

    &:after {
        content: '\f078';
        font-family: 'Font Awesome 5 Pro';
        display: inline-block;
        margin-left: 10px;
    }
}

