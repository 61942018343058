.nav-toolbar {
    border-top: 1px solid var(--color-borders);
    align-items: center;
    display: flex;
    justify-content: center;
    height: 110px;

    ul {
        display: flex;
        align-items: center;
        height: 100%;
    }

    li {
        display: flex;
        align-items: center;
        padding-bottom: 0px;
        width: 50vw;
        height: 100%;
        padding-left: 30px;
        padding-right: 30px;

        &:first-child {
            border-right: 1px solid var(--color-borders);   
            justify-content: flex-end;
            
            @media (min-width: 992px){
                padding-right: 60px;
                padding-left: 0;
            }
        }

        &:last-child {
            justify-content: flex-start;
            
            @media (min-width: 992px){
                padding-left: 60px;
                padding-right: 0;
            }    
        }
    }

    &__item {
        display: inline-block;
        letter-spacing: normal;
        position: relative;
        overflow: hidden;
        padding-bottom: 10px;

        &:hover {
            opacity: .8;
        }

        &:after {
            content: '';
            width: 100%;
            height: 2px;
            position: absolute;
            bottom: 0;
            left: 0;
            background-color: transparent;
            overflow: hidden;

            transition: transform .25s ease 0s;
        }
        
        &.is-active:after {
            background-color: var(--color-primary);
        }

        > a {
            text-decoration: none;
        }
    }

}