/* --------------------------------

File#: _1_tooltip
Title: Tooltip
Descr: A popup displaying additional text information
Usage: codyhouse.co/license

-------------------------------- */

:root {
  --tooltip-triangle-size: 12px;
}

.tooltip-trigger { // style inline-text tooltip trigger
  white-space: nowrap;
  border-bottom: 1px dotted var(--color-contrast-high);
  cursor: help;
}

.tooltip { // tooltip element - created using js
  display: inline-block;
  position: absolute;
  z-index: var(--zindex-popover);
  padding: var(--space-xxs);
  border-radius: var(--radius-sm);
  max-width: 200px;
  background-color: alpha(var(--color-contrast-higher), 0.98);
  box-shadow: var(--shadow-md);
  color: var(--color-bg);
  font-size: var(--text-sm);
  line-height: 1.4;
  @include fontSmooth;
  transition: opacity 0.2s, visibility 0.2s;

  a {
    color: inherit;
    text-decoration: underline;
  }

  @supports (clip-path: inset(50%)) {
    &::before { // tooltip triangle
      content: '';
      position: absolute;
      background-color: inherit;
      border: inherit;
      width: var(--tooltip-triangle-size);
      height: var(--tooltip-triangle-size);
      clip-path: polygon(0% 0%, 100% 100%, 100% 100%, 0% 100%);
    }
  }
}

.tootip:not(.tooltip--sticky) {
  pointer-events: none;
}

/* size variations */
.tooltip--sm {
  max-width: 150px;
  font-size: var(--text-xs);
  padding: var(--space-xxxs) var(--space-xxs);
}

.tooltip--md {
  max-width: 300px;
  padding: var(--space-xs) var(--space-sm);
}

.tooltip--lg {
  max-width: 350px;
  font-size: var(--text-base-size);
  padding: var(--space-xs) var(--space-sm);
}

.tooltip--top::before, .tooltip--bottom::before {
  left: calc(50% - var(--tooltip-triangle-size) / 2);
}

.tooltip--top::before {
  bottom: calc(var(--tooltip-triangle-size) * -0.5);
  transform: rotate(-45deg);
}

.tooltip--bottom::before {
  top: calc(var(--tooltip-triangle-size) * -0.5);
  transform: rotate(135deg);
}

.tooltip--left::before, .tooltip--right::before {
  top: calc(50% - var(--tooltip-triangle-size) / 2);
}

.tooltip--left::before {
  right: calc(var(--tooltip-triangle-size) * -0.5);
  transform: rotate(-135deg);
}

.tooltip--right::before {
  left: calc(var(--tooltip-triangle-size) * -0.5);
  transform: rotate(45deg);
}

.tooltip--is-hidden {
  // class used in js to hide the tooltip element before its top/left positions are set
  visibility: hidden;
  opacity: 0;
}