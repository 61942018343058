.info-tip {
    @media (max-width: 992px){
        margin-bottom: 30px;
    }

    .subtitle,
    .title {
        line-height: 1;
        display: block;
    }

    .title {
        margin-bottom: 0;
        line-height: 1.27;

        @media (min-width: 992px) {
            margin-bottom: 17px;
        }
    }

    .subtitle {
        font-weight: 400;
    }
}

.contract-list{
    .g-gallery__item{
        margin: 0;
    }
}