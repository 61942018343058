
.res-area {
    &__login {
        min-height: 30vh;

        .btn--secondary,
        .btn-primary {
            @extend .btn;
            @extend .btn--primary;        
        }

        button,
        .btn--touch {
            max-width: 250px;
            margin: auto;
        }

        .form-group:last-of-type {
            text-align: center;            
        }

        // only for button login
        button.btn-primary {
            padding: 0 30px;
            min-height: 45px;
            width: 100%;
            margin: auto;
            margin-top: 15px;
        }
    }

    &__reset,
    &__register {
        margin-top: var(--space-lg);
        margin-bottom: var(--space-lg);

        h5 {
            font-weight: 600;
            font-size: 24px;
        }

        .btn {
            min-width: 250px;

            span {
                display: block;
                width: 100%;
            }
        }
    }
}

.is-login {
    padding-top: 90px;

    @media (min-width: 992px) {
        padding-top: 130px;
    }

    /*
    .g-header {
        background-color: #dedee0;
    }

    &.menu-is-open {
        padding-top: 0;

        .g-header {
            background-color: #f7f7f9;
        }
    }
    */
}

.toolbox-download {
    // Only reserve area
    /*.is-reserved-area & {
        ul {
            display: grid;
            grid-template-columns: repeat(2,1fr);
            align-items: flex-start;
            column-gap: 1rem;
            flex: inherit;

            @media (min-width: 992px){
                grid-template-columns: repeat(4,1fr);
            }
        }
    }*/

    // Content fix
    .info-tip--download {
        text-align: center;
        width: 100%;
        padding: 1rem;
    }

    // image preview fix
    .preview-image {
        margin: auto;
        //margin-right: 0;
        margin-bottom: 30px;

    }

    .download-list li {
        .is-reserved-area & {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }

    // senza preview image
    .download-list li > a {
        &.not-preview-image {
            align-items: center;
            justify-content: flex-start;
            flex-direction: row;

            .title {
                display: flex;
                align-items: center;
                margin-bottom: 0;

                &::before {
                    margin-top: 0;
                }
            }
        }
    }
}


// Add grid only if not columns
.toolbox-column .toolbox-download {
    // Only reserve area
    .is-reserved-area & {
        ul {
            display: flex;
            flex-direction: column;
        }

        .info-tip--download {
            padding: 20px;
        }
    }
}