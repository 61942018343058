@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;1,400&display=swap');

.f-nunito {
  font-family: var(--font-secondary);
}
.f-merry {
  font-family: var(--font-primary);
}

@font-face {
  font-family: 'EleborFont';
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  src: url('../fonts/EleborSimbols.eot') format('embedded-opentype'), /* Internet Explorer */
  url('../fonts/EleborSimbols.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/EleborSimbols.woff') format('woff'), /* Pretty Modern Browsers */
  url('../fonts/EleborSimbols.ttf')  format('truetype'), /* Safari, Android, iOS */
  url('../fonts/EleborSimbols.svg') format('svg'); /* Legacy iOS */
}
