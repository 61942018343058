
section {
  // more height with vh  
  &.height-10 {
    height: 10vh;
  }
  &.height-20 {
    height: 20vh;
  }
  &.height-30 {
    height: 30vh;
  }
  &.height-35 {
    height: 35vh;
  }
  &.height-40 {
    height: 40vh;
  }
  &.height-50 {
    height: 50vh;
  }

  // commento al momento non ci sono riferimenti nella bozza
  /*&.with-bg {
    background-size: 1920px 500px;
    background-position: center -55px;
    background-repeat: no-repeat;
  }*/

  &.with-borders {
      border-top: 1px solid var(--color-borders);
      border-bottom: 1px solid var(--color-borders);
  }
  &.with-border-bottom {
    border-bottom: 1px solid var(--color-borders);
  }
  &.with-border-top {
    border-top: 1px solid var(--color-borders);
  }

  &.with-svg {
    position: relative;

    > .svg {
      display: inline-block;
    }

    > .svg,
    > svg {
        position: absolute;
        top: -2px;
        left: 0;
        right: 0;
        z-index: 0;
        max-width: 100%;
        margin: auto;
        display: none!important; // nascondo al momento

        @media (min-width: 767px){
            top: -11px;
        }
    }

    .container {
      position: relative;
      z-index: 1;
    }

    .h1 {
        font-family: var(--font-primary);
        display: inline-block;

        &.lower-case {
            text-transform: capitalize;
            
            &::first-letter {
                text-transform: uppercase;
            }
        }
    }

    .h1,
    h2 {
      padding-top: 10vw;
    }

    .text-center {
        .h4 {
            font-weight: 400;
            text-transform: uppercase;
            //margin-left: 30px;
            //line-height: .5;
        }
    }

    // New fix collection template
    .text-design,
    .description-intro {
        margin-top: 30px;
    }

  }
}

.bg--primary {
  background-color: var(--color-primary);
  color: white;

  p {
    color: white;
  }
}

.bg--grey {
  background-color: var(--grey-sm);
}

.bg--white {
  background-color: white;
}

.bg--blue {
  background-color: var(--color-indoor);
}

.bg--orange {
  background-color: var(--color-outdoor);
}

.bg--default {
  background-color: var(--color-neutro);
}


.bg--svg {
    position: relative;

    background-repeat: no-repeat; 
    background-position: bottom right; 
    background-size: 23%; 
    background-image: url("data:image/svg+xml,%3Csvg width='508' height='492' viewBox='0 0 508 492' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M122.489 542.126L122.495 542.13C162.918 566.384 210.904 578.5 266.435 578.5C294.188 578.5 319.426 576.23 342.149 571.686C365.358 567.649 393.099 561.344 425.371 552.772L425.382 552.768C453.116 544.7 472.22 540.433 482.739 539.932L483.216 539.909V539.432V339.455L522.879 332.72L523.296 332.649V332.227V281.56V281.06H522.796H292.903H292.403V281.56V332.227V332.682L292.856 332.725L372.563 340.244V502.152C363.569 510.082 350.343 516.559 332.846 521.559C315.249 526.586 296.39 529.102 276.266 529.102C251.642 529.102 230.183 523.449 211.871 512.161C193.559 500.872 178.365 483.93 166.299 461.307C142.151 415.525 130.058 355.62 130.058 281.56C130.058 205.988 144.167 148.382 172.315 108.673L172.317 108.671C200.455 68.4729 239.121 48.386 288.366 48.386C311.025 48.386 329.369 50.6526 343.417 55.168L343.425 55.1705L343.433 55.1727C357.438 59.1741 366.875 63.6489 371.848 68.5535L392.236 165.204L392.319 165.601H392.725H469.104H469.575L469.603 165.13L477.921 24.4725L477.946 24.0496L477.533 23.9555C408.942 8.3207 347.145 0.5 292.147 0.5C233.6 0.5 182.347 11.8564 138.403 34.5861C94.4515 57.3197 60.3494 90.9181 36.1036 135.369L36.1016 135.373C12.3598 179.825 0.5 233.098 0.5 295.172C0.5 349.181 10.8479 397.655 31.5549 440.584C52.2657 483.521 82.5788 517.372 122.489 542.126Z' stroke='%23ffffff'/%3E%3C/svg%3E%0A");

    /*[data-theme=outdoor] & {
        background-image: url('../images/g_bg_section_o.svg');
    }*/

    .container {
        position: relative;
        z-index: 1;
    }
}
