.info-tip {
    position: relative;
    padding: 15px;
    border: 4px solid transparent;

    @media (min-width: 992px){
        padding: 30px;
    }

    &__icon,
    &__img {
        margin-bottom: 30px;
    }

    &__content {
        font-size: 14px;
        margin-bottom: 20px;
    }
    &__img {
        img {
            //height: 230px;
        }
    }
    &__icon {
        display: flex;
        justify-content: center;
        align-items: center;

        > i {
            font-size: 50px;
            padding: 20px 30px;
            color: var(--color-text);
            background-color: var(--grey-md);
            border-radius: 50%;
            width: 95px;
            height: 95px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    
    &__link {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
    }

    .btn--link {
        font-size: 16px;
        line-height: 1.56;
        text-transform: uppercase;
        color: var(--color-text);

        &:before {
            opacity: 1;
            transition: opacity .25s ease 0s;
        }
    }

    &:hover .btn--link:before {
        opacity: .4;
    }

    &.border--none {
        border: none;
    }
    &.border--neutro {
        border-color: var(--color-neutro);
    }
    &.border--indoor {
        border-color: var(--color-indoor);
    }
    &.border--outdoor {
        border-color: var(--color-outdoor);
    }
    &.border--bianco {
        border-color: white;
    }

    &.text--bianco {
        color: white;

        i,
        .btn--link {
            color: white;

            &:after,
            &:before {
                background-color: white;
            }
        }
    }
    &.text--nero {
        color: var(--color-text);

        i,
        .btn--link {
            color: var(--color-text);

            &:after,
            &:before {
                color: var(--color-text);
            }
        }
    }
    &.text--grigio {
        color: var(--grey-lg);

        i,
        .btn--link {
            color: var(--grey-lg);

            &:after,
            &:before {
                color: var(--grey-lg);
            }
        }
    }
}