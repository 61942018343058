
.is-news {
    /*.main-content {
        margin-top: -75px;
        @media (min-width: 992px){
            margin-top: -150px;
        }
    }*/

    .g-news__box {
        margin-bottom: var(--space-lg);
    }

}