
.g-prod {
    &__title {
        margin-bottom: 10px;
        color: var(--color-primary);
        //font-size: 55px;

        @media (min-width: 992px){
            margin-bottom: 5px;
            //font-size: 100px;
            //line-height: 1.2;
        }
    }

    &__designer {
        font-size: 20px;
        padding-top: 20px;
        padding-bottom: 15px;
        margin-bottom: 35px;
        border-bottom: 1px solid var(--color-text);

        @media (min-width: 992px){
            margin-bottom: 65px;
        }
    }

    &__description {
        margin-bottom: 30px;

        p {
            margin-bottom: 0;
        }

        @media (min-width: 992px){
            margin-bottom: 60px;
        }
    }

    &__details {
        .lead {
            margin-top: 25px;
            margin-bottom: 30px;

            @media (min-width: 767px){
                margin-top: 20px;
                margin-bottom: 60px;
            }
        }

        .title-quickship {
            @extend .style-quickship;
            margin-top: 30px;
            margin-bottom: 20px;
            display: block;            
        }

        // anchor link to specifics
        .details-anchor-link {
            display: flex;
            align-items: center;
            font-weight: 400;
            text-decoration: none;

            &:after {
                content: '\f175';
                font-family: 'Font Awesome 5 Pro';
                display: inline-block;
                margin-left: 10px;
            }
        }
    }

    &__conf {
        .conf-text {
          font-size: 19px;
          line-height: 1.21;
          color: white;
          margin-bottom: 20px;
          display: inline-block;

          @media (min-width: 992px) {
            font-size: 24px;
            line-height: 1.17;
            margin-bottom: 0px;
          }
        }

        .conf-btn {
          @extend .btn--touch;

          @media (max-width: 992px){
            width: inherit;
            
            > span {
                padding: 9px 30px;
            }
          }
        }
    }

    &__texture {
      h4 {
        font-weight: 600;
      }

      header {
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (min-width: 992px){
            margin-bottom: 60px;
        }
      }

      .grid {
          figure {
            //text-align: center;
            max-width: 100%;
          }
      }

      .texture-bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;

        @media (min-width: 992px){
            flex-direction: row;
        }

        .right {
            width: 100%;

            @media (min-width: 992px){
                width: 50%;
                display: flex;            
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
            }

            .btn {
                margin-top: 15px;
                @media (min-width: 992px) {      
                    margin-top: 0;
                }
            }
        }
      }
    }

    &__downloads {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        @media (min-width: 767px){
            flex-direction: row;
            justify-content: flex-end;
        }
        
        .download-3d {
            margin-top: 30px;

            @media (min-width: 767px){
                margin-top: 0;
                margin-left: 30px;
            }
        }
    }

    &__view {
      min-height: 40vh;
    }
}

.g-material {
  &.slick-slider {
    max-height: 255px;
    margin-right: 30px;
  }

  &__item {
    > div {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin: 0 -5px;
    }

    img {
      margin: 0 5px;
      margin-bottom: 20px;

      @media (max-width: 992px){
        max-width: 57px;
      }
    }
  }

  .slick-prev {
    display: none;
  }

  .slick-arrow {
    position: absolute;
    top: 50%;
    right: -30px;
    transform: translateY(-50%);
    color: var(--grey-md);
    cursor: pointer;
  }
}

.spot-catalog {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 340px;

    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;

    &:after,
    &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 3;
    }

    &:after {
        background-image: linear-gradient(to top, #001488, #00148800);
        opacity: 1;
        transition: opacity .25s ease 0;
    }
    /*&:before {
        transition: opacity .25s ease 0;
        background-image: linear-gradient(to top, #001488 10%, #00148800);
        opacity: 0;
    }
    &:hover:before {
        opacity: 1;
    }*/

    &__icon {
        margin-bottom: 15px;
    }

    &__link {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
    }

    &__name {
        text-align: center;
        position: relative;
        z-index: 5;

        h3 {
            color: white;
            font-size: 28px;
            line-height: 1;
        }
    }

    .btn--link {
        color: white;
        margin-top: 30px;
        opacity: 0;

        transition: opacity .25s ease 0s;

        &:before,
        &:after {
            background-color: white;
        }
    }

    &:hover {
        .btn--link {
            opacity: 1;
        }
    }
}

.list-variant__item {
    height: auto;
    overflow: hidden;

    &.not-visible {
        height: 0px;
        margin-bottom: 0;
    }

    .item__info,
    .img-cont {
        padding: 0 1rem;
    }
}

.item__info {
    display: flex;
    flex-direction: column;

    .title-links {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 10px;
    }

    .downloads {
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 15px;
    }

    .text-links {
        p {
            font-size: 14px;            
        }

        .btn {
            margin-top: 20px;
        }
    }
}

// Section progetti
.projects {
    &__grid {
        min-height: 570px;

        @media (min-width: 992px){
            min-height: 570px;
        }

        > div {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }

    &__text,
    &__static {
        padding-right: 0;

        @media (min-width: 992px){
            padding-right: 2rem;
        }
    }

    &__text {
        padding-top: 24px;
        border-top: 1px solid var(--color-borders);
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        @media (max-width: 992px){
            margin-bottom: 30px;
        }
    }

    &__names {
        width: calc(100% - 115px);

        @media (min-width: 992px){
            width: calc(100% - (140px + 2rem));
        }

        // title proj
        h5 > a {
            opacity: .95;
            text-decoration: none;
            transition: opacity .25s ease;

            &:hover {
                opacity: 1;
            }
        }
    }

    &__arrows {
        display: flex;
        align-items: center;

        .slick-arrow {
            position: static;
            margin: 0 15px;
            width: auto;
            height: auto;
            line-height: normal;
            transform: none;

            @media (min-width: 767px){
                margin: 0 25px;
            }
        }

        .slick-arrow:before {
            font-family: 'Font Awesome 5 Pro';
            font-size: 40px;
            color: var(--color-text);
        }
        .slick-prev:before {
            content: '\f177';
        }
        .slick-next:before {
            content: '\f178';
        }

        .slick-next {
            @media (max-width: 992px){
                margin-right: 0px;
            }
        }
    }

    &__images {
        figure {
            position: relative;

            .full-link {
                @extend .full;
                z-index: 5;
            }
        }
    }
}
