
.back-to-top {
    background-color: white;
    padding: 0;
    bottom: 50px;
    right: 50px;
    border-radius: 50%;  
    width: 38px;
    height: 38px;
    text-align: center;
    
    transition: all .25s ease-in-out 0s;

    .touchevents & {
        display: none!important;
    }

    > svg {
        height: 100%;
    }
    
    &:hover {
        //background-color: white;
        opacity: .6;
    }
}

.slick-track,
.slick-list {
    height: 100%;
}

.slick-slide {
    height: auto;
}

.tooltip-trigger {
    border-bottom: none;
}

.tooltip--text-xs {
    font-size: var(--text-xs);
}

/*.tooltip {
    top: 50%;
    background-color: white;
    color: var(--grey-lg);
    font-family: var(--font-primary);
    font-size: 12px;
    font-weight: 900;
    word-break: break-word;
    text-transform: uppercase;
    max-width: 130px;
    text-align: center;
    padding: 13px 15px;
    margin-top: 32px;
}*/

.details-list-v2__item {
    &:last-child {
        border-bottom: none;
    }
}

.details-list-v2__dd {
    .btn,
    .btn--touch,
    .btn--link {
        margin-right: 10px;
    }

    .btn,
    .btn--touch {
        margin-top: 10px;
    }
}