
.g-box {
  &--bgtext {
    width: 100%;
    height: 313px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    font-size: 38px;
    text-transform: uppercase;

    display: flex;
    align-items: center;
    justify-content: center;

    &.light {
      color: white;
    }

    &.dark {
      color: #28282d;
    }
  }
}

.g-news {
    &__tag {
        @extend .btn--link;

        .g-news__box & {
            color: white;
            border-bottom: none;
            position: relative;
        }

        &:after {
            content: '';
            width: 50%;
            height: 2px;
            position: absolute;
            bottom: 0;
            right: 0;
            background-color: var(--color-outdoor);
        }

        &:before {
            content: '';
            width: 50%;
            height: 2px;
            position: absolute;
            bottom: 0;
            left: 0;
            background-color: var(--color-indoor);
        }
    }
    &__box {
        height: 350px;
        background-size: cover;
        background-position: center;
        position: relative;

        @media (min-width: 1140px) {
            height: 450px;
        }

        @media (min-width: 1280px){
            height: 600px;
        }

        .is-news & {
            transition: box-shadow .5s,transform .5s;
        }
        
        &:hover {
            .is-news & {
                box-shadow: 0 24px 18px -18px rgba(0,0,0,0.4);
                transform: translateY(-20px);
            }
            
            html:not(.touchevents) & {
                .g-news__content {
                    transform: translateY(-40px);
                }
                .btn--touch {
                    opacity: 1;
                    //visibility: visible;
                }
            }

            // hover all
            .btn--touch > span {
                background-color: rgba(255,255,255,.6);
            }

            &:after {
                background-color: rgba(0,0,0,.5);
            }
        }

        
        &:after {
            content: '';
            background-color: rgba(0,0,0,.2);
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 5;

            transition: background-color .5s;
        }

        h4 {
            color: white;
            font-weight: 600;
            line-height: 1.14;
            margin-top: 10px;
            margin-bottom: 10px;
        }
        .lead {
            color: white;
        }

        .btn--touch {
            opacity: 0;
            //visibility: hidden;
            transition: opacity .2s ease 0s;
            margin-top: 20px;

            position: relative;
            z-index: 7;

            @media (max-width: 1025px){
                display: none;
            }

            > span {
                color: white;
            }
        }

        .full {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 10;
            width: 100%;
            height: 100%;
        }
    }

    &__content {
        position: absolute;
        top: auto;
        bottom: 35px;
        padding:  0 35px; 
        padding-top: 0;
        z-index: 7;

        // Transition box
        transition: transform .5s ease 0s;

        @media (min-width: 1400px){
            top: 50%;
            bottom: auto;
            padding: 0 75px;
        }

        @media (min-width: 1024px){
            padding: 0 45px;
        }
    }
}
