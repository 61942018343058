.google-maps {
    width: 100%;
    height: 500px;
    padding-bottom: 0;
    background-color: #f0f0f0;
}

.toolbox-googlemap{
    min-height: 400px;

    @media (min-width: 767px){
        min-height: 600px;
    }
}
