
.materials {
    &__box {
        position: relative;
        background-color: white;
        background-size: cover;
        background-position: center;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;

        /*img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }*/

        .full-link {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 10;
            cursor: pointer;            
        }

        .btn--link {
            color: white;
            position: relative;
            z-index: 5;
            
            //transform: translateY(15vh);
            opacity: 0;

            .is-prodotto & {
                display: none;
            }

            &:before,
            &:after {
                background-color: white;
            }
        }        

        &:hover {
            &:after {
                opacity: 1;
            }

            .btn--link {
                opacity: 1;
                transition: opacity .5s ease .1s;
            }
        }

        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            z-index: 3;
            width: 100%;
            height: 100%;
            opacity: 0;
            background-image: linear-gradient(to top, var(--color-primary), #00148800);

            transition: opacity .25s ease 0s;

            .is-prodotto & {
                display: none;
            }
        }

        &:before {
            content:'';
            display:block;
            padding-bottom:100%;
        }

        &__image {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    &__type {
        h6 {
            margin-bottom: 20px;
        }

        .types__title {
            margin-bottom: 0;
        }
    }
}

#modal-material {
    overflow-y: auto;
    
    .variant {
        &__title {
            display: inline-block;
            font-family: var(--font-primary);            

            &::first-letter {
                text-transform: uppercase;
            }            
        }

        &__description {
            line-height: 1.4;

            h4,h5 {
                line-height: .8;
                margin-top: var(--space-sm);
                margin-bottom: var(--space-xs);
            }

            small,
            p {
                margin-bottom: var(--space-sm);
            }
        }

        &__wash-info {
            h5 {
                line-height: .8;
                margin-top: var(--space-sm);
                margin-bottom: var(--space-xs);
            }

            small {
                margin-bottom: var(--space-sm);
            }

            ul {
                display: flex;
                flex-wrap: wrap;
                margin-top: var(--space-sm);
                font-family: 'EleborFont';
                -moz-osx-font-smoothing: grayscale;
                -webkit-font-smoothing: antialiased;

                li {
                    list-style: none;
                    margin-right: 8px;
                    font-size: 32px;
                    line-height: 1;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }

    // varianti modale
    .other-variants {
        img {
            margin-bottom: 20px;
        }
        .name {
            font-size: 1rem;
            font-family: var(--font-primary);
            font-weight: bold;
            line-height: 1.2;
            text-transform: capitalize;
        }
    }
}