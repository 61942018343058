
.g-filters {
  //background-color: #f7f7f9;
  border-bottom: 1px solid var(--color-borders);

  &__lbl {
    display: block;
    font-size: 16px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 3px;
    //color: #6d6d7a;
    padding-bottom: 12px;
    margin-bottom: 20px;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background-image: linear-gradient(to right, #dedee0, white);
    }
  }

  &__items {
    .pos-bottom-right {
        text-align: center;

        @media (min-width: 992px){
            text-align: right;
        }
    }

    .is-materials & {
        border-top: 1px solid var(--grey-md);
    }

    > div {
      margin-bottom: 0;
    }

    label {
        font-size: 14px;
        font-weight: 300;
        display: block;
        margin-bottom: 10px;

        @media (min-width: 992px) {
            font-size: 18px;
            margin-bottom: 30px;
        }
    }

    input[type=search],
    select {
      border: none;
      padding: 5px 0;
      //font-family: var(--font-primary);
      font-size: 14px;
      font-weight: 300;
      letter-spacing: 2.6px;
      background: none;
      width: 100%;
      text-transform: uppercase;
      color: rgba(35,46,53,.6);
      border-radius: 0;
      border-bottom: 1px solid rgba(35,46,53,.4);
      padding-right: 20px;

      appearance: none;
      -webkit-appearance: none;

      transition: border-color .25s ease 0s;

      @media (min-width: 992px) {
        font-size: 16px;
        padding: 9px 0;
        letter-spacing: 3px;
      }

      @media (max-width: 767px) {
        padding-top: 0;
      }

      &:focus {
        outline: 0;
        border-color: rgba(35,46,53,.6);
      }

      &::placeholder {
        color: var(--grey-lg);
      }
    }

    .input--custom,
    .select--custom {
      position: relative;
      margin-bottom: 15px;
      z-index: 1;

      @media (min-width: 992px){
        margin-bottom: 0;
      }

      &:after {
        font-family: 'Font Awesome 5 Pro';
        font-size: 14px;
        color: var(--grey-lg);
        position: absolute;
        right: 0;
        bottom: 12px;
        z-index: -1;

        @media (min-width: 992px) {
            bottom: 15px;
        }
      }

      &.icon-search {
        &:after {
          content: '\f002';
        }
      }

      &:before {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;
        background-color: var(--color-primary);
        width: 0px;
        height: 2px;

        transition: all .25s ease-in-out;
      }

      &:hover {
        &:before {
          background-color: var(--color-contrast-low);
          width: 100%;
        }
      }

        &:focus {
            &:before {
                width: 100%;
            }
        }
    }

    .select--custom {
        &:after {
            content: '\f078';
        }
    }

    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;

      option {
        color: #6d6d7a;
      }
    }

    .col__quickship {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .col__quickship label {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        @media (min-width: 992px){
            margin-bottom: 0;
        }

        ._quickship {
            @extend .style-quickship;
            margin-left: 15px;
        }
    }

    input[type=checkbox] {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        width: 18px;
        height: 18px;
        background-color: white;
        border: 1px solid var(--color-borders);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        &:after {
            content: '\f00c';
            color: var(--color-text);
            font-family: "Font Awesome 5 Pro";
            font-size: 14px;
            opacity: 0;
            transition: opacity .25s ease-in-out 0s;
        }

        &:checked {
            &:after {
                opacity: 1;
            }
        }
    }

    .pos-bottom-right {
        .filter-reset {
            cursor: pointer;
            margin-top: 30px;
            text-transform: uppercase;

            @media (min-width: 992px){
                margin-top: 0;
            }
        }
        
        @media (max-width: 992px) {
            justify-content: center;
        }
    }
  }

  // filters locator
  &--locator {
      .back-link {
          @media (max-width: 992px){
            margin-top: 30px;
          }
      }
  }
}
