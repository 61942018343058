.ar-dropdown {
    position: relative;

    &__toggle {
        display: flex;
    }

    &__menu {
        position: absolute;
        top: calc(100% + 10px);
        right: 0;
        background-color: white;
        z-index: 10;
        min-width: 200px;
        padding: 1rem;
        font-size: 1rem;
        line-height: 1.4;
        text-align: right;
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s ease-in-out .25s, opacity .25s ease-in-out;

        &.is-hidden {
            display: block!important;
            opacity: 0;
            visibility: hidden;
            transition-delay: 0s;
        }

        &.is-show {
            opacity: 1;
            visibility: visible;
            transition-delay: 0s;
        }

        h6 {
            margin-bottom: .5rem;
        }

        ul {
            > li {
                > a {
                    font-weight: 300;
                    text-decoration: none;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            // item with logout link
            li:last-child {
                margin-top: .7rem;
                
                .logout-link {
                    font-size: 12px;
                    text-decoration: underline;
                }
                .logout-link:hover {
                    text-decoration: none;
                }
            }
        }
    }
}