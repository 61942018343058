
.g-img {
  &__cap {
    text-align: right;
    position: relative;

    &__text {
      display: inline-block;
      font-size: 38px;
      line-height: 1;
      font-weight: 300;
      color: white;
      background-color: var(--color-primary);
      padding: 30px 0;
      padding-left: 60px;
      margin-bottom: -50px;
      position: relative;
      z-index: 1;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 100%;
        width: 999px;
        height: 100%;
        background-color: var(--color-primary);
      }
    }

    &__link {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
  }
}