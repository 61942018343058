.flex-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    .g-footer & {
        max-width: 960px;
        padding: 0 15px;
        margin: auto;
    }

    &.with-column {
        flex-direction: column;    
    }
}