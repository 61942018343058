
.product-list {

    .grid .col-3 {
        width: 25%;
    }

    &__item {
        position: relative;

        &:hover {
            .img-cont {
                border-color: var(--color-primary);
            }
        }

        &.color--indoor:hover {
            .img-cont {
                border-color: var(--color-indoor);
            }
        }

        &.color--outdoor:hover {
            .img-cont {
                border-color: var(--color-outdoor);
            }
        }

        .img-cont {
            width: 100%;
            margin-bottom: 20px;
            //padding-bottom: 50px;
            text-align: center;
            border-bottom: 1px solid var(--color-borders);
            display: flex;
            justify-content: center;
            align-items: center;

            transition: border .25s ease-in-out 0s;

            @media (min-width: 1280px){
                min-height: 225px;
                margin-bottom: 30px;
            }

            img {
                display: flex;
                width: auto;
                //height: 125px;
            }
        }

        h3 {
            font-size: 20px;
            color: var(--color-text);
            margin-bottom: 10px;

            @media (min-width: 992px) {
                font-size: 24px;
            }
        }

        ._quickship {
            color: var(--color-quickship);
            display: block;
            text-align: left;
            font-size: 12px;
            font-weight: bold;
            letter-spacing: 2.25px;

            @media (min-width: 992px){
                font-size: 14px;
                letter-spacing: 2.63px;
            }
        }
    }
}

// part of box grid items
.item-prod {
    &__link {
        @extend .full;
    }
    
    // h4 actually
    &__name {
        font-size: var(--text-md);
        margin-bottom: var(--space-xs);
    }

    // new line collection
    &__collection {
        margin-bottom: 15px;
    }
}

// Correlati
.correlati__grid {
    row-gap: 30px;
}
