.menu-lang {
  > ul {
    display: flex;
    text-transform: capitalize;

    > li + li {
      margin-left: 10px;
    }

    a.active {
      text-decoration: underline;
    }
  }

  @media(min-width: 768px) {
    display: none;
  }
}