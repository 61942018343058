
.search {
    &__form {
        .search-form {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            @media (min-width: 992px){
               flex-direction: row;
            }
        }

        .form-control {
            border-radius: 0;
            width: 100%;

            @media (min-width: 992px){
                min-width: 70%;
                width: auto;
            }
        }
    
        .btn {
            margin-left: 15px;
            margin-top: 15px;
            padding: 17px;
            width: 100%;
            max-width: 200px;

            @media (min-width: 992px){
                min-width: 150px;
                width: auto;
                margin-top: 1px;
            }
        }
    }

    &__list {
        margin-top: 50px;
    }
}

.searchresult {
    display: flex;
    flex-direction: column;
    border-bottom: 1px dashed #ddd;
    padding-bottom: 30px;
    margin-bottom: 40px;

    @media (min-width: 992px){
        flex-direction: row;
    }

    &:last-of-type {
        border-bottom: none;
    }

    img {
        margin-bottom: 10px;
    }

    .media-body {
        @media (min-width: 992px){
            margin-left: 30px;
        }
    }

    small {
        font-weight: 600;
        font-size: 14px;
    }
}

.media-heading > a {
    text-decoration: none;
    color: #28282d;
}

.search-media {
    &__img {
        max-width: 120px;
    }
}