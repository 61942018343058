
:root {
  --color-indoor: #112C4E;
  --color-outdoor: #DE441F;
  --color-neutro: #F0EEEC;

  --grey-xl: #28282d;
  --grey-lg: #6d6d7a;
  --grey-md: #dedee0;
  --grey-sm: #f7f7f9;

  --aspect-ratio-gallery: 0.6;
}