@media print {
    .svg,
    .btn,
    #gallery,
    #info,
    .hero,
    .g-prod__conf,
    .g-nav,
    .g-stickynav,
    .g-header,
    .g-footer {
        display: none;
    }

    .accordion__item {
        float: left;
        width: 100%;
        page-break-inside: avoid;
    }

    .material__types {
        display: block;
        position: relative;
        margin: 0 -10px;
        width: 100%;

        & > * {
            float: left;
            width: 50%;
            padding: 0 10px;
            margin-left: 0;
            max-width: none;
        }
    }

    .materials__box {
        position: relative;
        display: inline-block;
        width: 100%;
        page-break-inside: avoid;
    }

    .materials__box__image {
        display: inline-block;
        width: 100%;
        page-break-inside: avoid;
        page-break-before: auto;
        page-break-after: auto;
    }
}