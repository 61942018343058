
.text-part {
  padding-top: 134px;

  ._line {
    &:after {
      top: -64px; // da verificare
      background-color: #dedee0;
    }
  }

  &__title {
    font-weight: 300;
    font-size: 50px;
    line-height: 1;
    letter-spacing: 3px;
    color: #70707c;
    margin-bottom: 30px;
    text-transform: uppercase;
  }

}

.label {
  &--line {
    &:after {
      background-color: #dedee0;

      [data-theme='inddor'],
      [data-theme='outddor'] {
        background-color: var(--color-primary);
      }
    }

    &.lg {
      font-size: 24px;
      letter-spacing: 3px;
    }
  }
}