
.content-prog {
    &--flex {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        margin-top: -20vh;
    }

    .lead {
        margin-bottom: 30px;
    }
}

.focus-prog {
    h2 {
        margin-bottom: 30px;

        @media (min-width: 992px){
            margin-bottom: 0;
        }
    }

    .grid-container {
        display: flex;
        flex-direction: column;

        @media (min-width: 992px){
            display: grid;
            grid-template-columns: 1fr 5fr 1fr 1fr 4fr 1fr 1fr;
            grid-column-gap: 30px;
            grid-row-gap: 30px;
            justify-items: stretch;
            align-items: stretch;
        }
    }

    .focus-img {
        @media (max-width: 992px){
            margin-bottom: 30px;
        }

        &.one {
            grid-column: 2 / 3;
            grid-row: 2 / 6;
        }

        &.two {
            grid-column: 4 / 7;
            grid-row: 1 / 3;
        }


        &.three {
            grid-column: 5 / 6;
            grid-row: 4 / 5;
        }
    }
}

// prodotti utilizzati
.products-prog {
    .product-list {
        row-gap: 30px;
        
        &.with-carousel {
            //height: 340px;

            img {
                outline: none;
            }

            .slick-slide {
                outline: none;
                margin-bottom: var(--grid-gap, 1em);
                margin-left: var(--grid-gap, 1em);
            }
        }
    }

    // link spot
    .product-list__item {
        .full-link {
            @extend .full;
        }
    }
}