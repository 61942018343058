
.business-card {
    &__box {
        padding: 15px;
        border: 1px solid var(--color-borders);
        //margin-bottom: var(--space-md);
        color: var(--color-text);

        transition: all .25s ease-in-out 0s;

        &:hover {
            background-color: var(--grey-sm);
            border-color: var(--grey-sm);
        }

        @media (min-width: 992px){
            padding: 30px;
        }
    }

    &__title {
        font-size: var(--text-md);
        line-height: 1.2;
        margin-bottom: 15px;
    }

    label {
        display: block;
        font-weight: bold;
        margin-top: 0;
        margin-bottom: 8px;
        line-height: 1;
    }

    .description {
        margin-bottom: 30px;
    }

    .contacts {
        > p {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            line-height: 1.33;
            color: var(--grey-lg);

            &:last-child {
                margin-bottom: 0;
            }
        }

        a {
            color: var(--color-text);
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }

        i {
            color: var(--color-text);
            margin-right: 10px;
        }

        &__cont {
            margin-bottom: 20px;

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        &__item {
            &.is-name {
                font-weight: bold;
            }
        }
    }

    &__grid {
        margin: 30px 0;
    }
}