
.g-stickynav {
    font-size: 16px;
    background-color: var(--color-neutro);
    border-bottom: 1px solid var(--color-borders);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;    
    width: 100%;

    transform: translateY(-90px);
    transition: transform .25s ease-in-out 0s;

    &.is-visible {
        transform: translateY(0);
    }

    .container {
        font-size: 16px;
        align-items: center;
        height: 50px;

        @media (min-width: 992px){
            height: 80px;
        }

        ._col:first-child,
        ._col:last-child {
            @media (min-width: 992px){
                min-width: 200px;
            }
        }
    }

    .prod-name {
        font-family: var(--font-primary);
        font-weight: bold;
    }

    &__list {
        ul {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            column-gap: 30px;

            @media (min-width: 1200px){
                column-gap: 60px;
            }

            li {

                > a {
                    text-transform: capitalize;
                    text-decoration: none;
                    line-height: normal;
                }

                &.is-quickship a {
                    color: var(--color-quickship);
                    font-weight: bold;
                    text-transform: uppercase;            
                }
            }
        }
    }
}