
.privacy__link,
.privacy__check {
    margin-bottom: var(--space-sm);
    font-size: var(--text-xs);
}

.privacy__link {
    [data-extera-gdpr] {
        margin-bottom: 0;
    }
}

.privacy__check {
    .label-hint > p {
        margin-bottom: 0!important;
    }
    > .form-check {
        align-items: flex-start;
        flex-direction: column;
    }
    .input-check {
        display: flex;
        align-items: center;
    }
}