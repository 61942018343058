
.single-col {
    &__part {
        padding-top: 134px;

        ._line {
            //margin-top: -64px;
            &:after {
                top: -64px; // da verificare
            }
        }
    }

    &__title {
        font-weight: 300;
        font-size: 50px;
        line-height: 1;
        letter-spacing: 3px;
        color: var(--color-primary);
        margin-bottom: 30px;
        text-transform: uppercase;
    }

    &__description {
        font-size: 20px;
        color: var(--color-text);
        line-height: 1.5;
    }

    &__info {
        h3 {
            text-transform: uppercase;
            font-size: 24px;
            font-weight: 300;
            line-height: 1;
            letter-spacing: 1.44px;
            //color: #6d6d7a;
            margin-bottom: 20px;
        }
    }

    &__seo {
        .btn-scroll {
            float: right;
        }
    }
}