
.download-list {
  li a {
    display: flex;
    align-items: center;
    //justify-content: center;
    text-decoration: none;
    flex-direction: column; // modfica visuale per non orizzontale ma verticale

    > span {
      margin-bottom: 30px;
      display: flex;
      font-size: 16px;
      color: #28282d;
    }
  }

  .preview-image {
    max-width: 200px;
    border: 2px solid #ccc;
    margin-right: 30px;
  }

  .title {
    margin-right: 15px;
    font-weight: 700;
    line-height: 1.25;
    text-decoration: none;

    &:before {
      content: '';
      width: 20px;
      height: 3px;
      background-color: var(--color-primary);
      display: inline-block;
      text-align: left;
      margin-right: 10px;
      margin-top: 12px;
    }
  }

  .file-info {

  }
}
